import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HomeMobileContainer } from './styles';
import { Footer } from '@uala/ui';
import { useHistory } from 'react-router-dom';
import Commissions from '../Commissions';
import { ButtonWatcher } from '../AccountRegistration/ButtonWatcher';
import Navbar from '../../components/UI/Navbar';
import BenefitsList from '../../components/UI/BenefitsList';
import { useTreatments } from '@splitsoftware/splitio-react';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { getEventNameByPathname } from '../../utils/amplitude';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';

interface StoreProps {
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  link?: string;
  onClick?: () => void;
}

interface HomeMobileProps {
  footer?: {
    variant?: 'light' | 'dark';
    position?: 'fixed' | 'absolute' | 'static';
    title?: string;
    stores?: {
      appStore?: StoreProps;
      googlePlay?: StoreProps;
    };
  };
}

const HomeMobile: React.FC<HomeMobileProps> = ({ footer = {} }) => {
  const device = useGetDevice();
  const history = useHistory();
  const [redirectPath, setRedirectPath] = useState('/personal-info');
  const treatmentsConfig = useTreatments([
    'experimental-onboarding-show-select-person-type-page',
  ]);
  const { treatment } =
    treatmentsConfig['experimental-onboarding-show-select-person-type-page'];
  useEffect(() => {
    if (treatment === 'on') {
      setRedirectPath('/select-person-type');
    }
  }, [treatment]);

  const handleSubmit = () => {
    createAmplitudeEvent(getEventNameByPathname(), { device });
    history.push(redirectPath);
  };
  useAmplitudEvent('registro_ppjj_landing_page_mobile');
  return (
    <HomeMobileContainer>
      <Navbar showFaq />
      <LinearGradientContainer>
        <Title>Usá la app de Ualá Bis para tu empresa</Title>
        <BenefitsList>
          <li>
            Recibí la plata en el acto en tu cuenta gratuita con la app de Ualá.
          </li>
          <li>Aceptá todas las tarjetas y ofrecé cuotas a tus clientes.</li>
          <li>Pagá comisiones bajas, bajas en serio.</li>
        </BenefitsList>
        <StoreImageContainer src="/images/mpos.png" alt="uala business" />
        <StyledFooter title={footer.title} stores={footer.stores} />
        <Commissions />
        <ButtonWatcher onClick={handleSubmit}>Completar registro</ButtonWatcher>
      </LinearGradientContainer>
    </HomeMobileContainer>
  );
};

export default HomeMobile;

const LinearGradientContainer = styled.div`
  margin-top: 64px;
  width: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 254, 254, 1) 95%,
    rgba(255, 235, 235, 1) 100%
  );
`;

const Title = styled.h1`
  text-align: center;
  margin-right: 28px;
  margin-left: 28px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 29px;
  line-height: 35px;
  color: var(--gris-uala);
`;

const StoreImageContainer = styled.img`
  width: 100%;
  background-image: url(/images/background.png);
  background-size: 100% 100%;
`;

const StyledFooter = styled(Footer)`
  @media (min-width: 0px) {
    position: initial;
    bottom: initial
    margin: 10px auto;

    & #footer-title {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
    }

    & div {
      flex-wrap: wrap;

      & span {
        flex: 0 0 100%;
        justify-content: center;
        margin-right: 0;
        margin-bottom: 14px;
      }

    }
  }
`;
