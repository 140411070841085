import React from 'react';
import useDesktopSize from '../../../hooks/useDesktopSize';
import Navbar from '../../UI/Navbar';
import FaqIcon from '../../UI/FaqIcon';
import { Stack, Box } from '@uala/abra';

export type LandingContainerProps = {
  children: React.ReactElement | React.ReactElement[];
  showNavBar?: boolean;
  showBackButton?: boolean;
  backButtomFunction?: () => void;
  showFaq?: boolean;
};

const LandingContainer = ({
  children,
  showNavBar = false,
  showBackButton = false,
  backButtomFunction = () => {},
  showFaq = false,
}: LandingContainerProps) => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });

  return (
    <>
      {showNavBar && !isDesktop && (
        <Navbar
          showBackButton={showBackButton}
          backButtomFunction={() => backButtomFunction()}
          showFaq={showFaq}
        />
      )}

      <Stack
        direction="column"
        align="center"
        css={{
          width: '100%',
          marginTop: 64,
          marginRight: 'auto',
          marginLeft: 'auto',
          padding: '$16 28px',
          '@lg': {
            padding: '$16 $24 90px',
            width: 320,
          },
        }}
      >
        {isDesktop && showFaq && (
          <Box css={{ position: 'relative', width: '100%' }}>
            <FaqIcon />
          </Box>
        )}
        {children}
      </Stack>
    </>
  );
};

export default LandingContainer;
