import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Title } from '../AccountRegistration/styles';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { getEventNameByPathname } from '../../utils/amplitude';
import { RowList, Row, Link } from '@uala/abra'
import LandingContainer from '../../components/Layout/LandingContainer';

const PersonalInfo = () => {
  const history = useHistory();
  const device = useGetDevice();
  return (
    <LandingContainer showNavBar showFaq>
      <Image src="/images/store.svg" alt="Informacion Personal" />
      <CustomTitle>¿Cómo querés registrarte?</CustomTitle>
      <RowList
        hasDivider
        css={{
          width: '100%',
          maxWidth: '320px'
        }}
        isActionable
      >
        <Row
          title="Como persona jurídica"
          description="El CUIT empieza con 30 o 33."
          onClick={() => {
            createAmplitudeEvent(getEventNameByPathname(), { device, user_type: 'company' });
            history.push('/first-step/1');
          }}
          isActionable
        />
        <Row
          title="Como persona física"
          description="Tu CUIT/CUIL comienza con 20, 23, 27."
          onClick={() => {
            createAmplitudeEvent(getEventNameByPathname(), { device, user_type: 'occasional' });
            window.open("/documents/Instructivo crear cuenta Ualá.pdf", "_blank");
          }}
          isActionable
        />
      </RowList>
      <Link
        as='button'
        onClick={() => {
          createAmplitudeEvent('registro_ppjj_info', { device })
          history.push('/about-ppjj')
        }}
        size='lg'
        css={{ marginTop: 'auto', marginBottom: '$32', textDecoration: 'none' }}
      >
        ¿Cómo sé si soy persona jurídica?
      </Link>
    </LandingContainer>
  );
};

const Image = styled.img`
  display: flex;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
`;

const CustomTitle = styled(Title)`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #3A3A3A;
  width: 100%;
  margin-bottom: 46px;
`;

export default PersonalInfo;
