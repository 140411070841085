import React, { FC } from 'react';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { SplitFactory } from '@splitsoftware/splitio-react';

const getUuid = () => {
  let userId = Cookies.get('userId');
  if (!userId) {
    userId = uuidv4();
    Cookies.set('userId', userId);
  }
  return userId;
};

const Split: FC<{ children: React.JSX.Element }> = ({ children }) => {
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY ?? '',
          key: getUuid(),
        },
        sync: {
          splitFilters: [
            {
              type: 'byName',
              values: ['experimental-onboarding-show-select-person-type-page'],
            },
          ],
        },
      }}
    >
      {children}
    </SplitFactory>
  );
};

export default Split;
