import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/Layout/FormContainer';
import { ButtonWatcher } from '../AccountRegistration/ButtonWatcher';
import {
  FormContainer as SubContainer,
  Subtitle,
  Title,
} from '../AccountRegistration/styles';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';

const ResumeRegistration = () => {
  const device = useGetDevice();
  useAmplitudEvent('registro_ppjj_recibe_mail_en_uso', { source: 'first-step/1' })

  const AMPLITUDE_EVENTS = {
    COMPLETA_FORMULARIO: 'registro_ppjj_completa_formulario',
    REGISTRA_OTRO_MAIL: 'registro_ppjj_registra_otro_mail',
  };

  const handleAmplitudeEvent = (eventName: string) => {
    createAmplitudeEvent(eventName, {
      source: 'first-step/1',
      device,
    });
  };

  const handleButton = () => {
    handleAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETA_FORMULARIO);
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSfFl6beSa0EC3G9Ht-2ehMT4bd5LxaIkydP9YHxHZc6-c-GgA/viewform',
      '_blank',
      'noopener'
    );
  };

  const handleLink = () => {
    handleAmplitudeEvent(AMPLITUDE_EVENTS.REGISTRA_OTRO_MAIL);
  };

  return (
    <Container>
      <SubContainer>
        <Image src="/images/resume-registration.png" alt="Retomar registro" />
        <CustomTitle>
          Ya comenzaste un
          <br />
          registro antes
        </CustomTitle>
        <CustomSubTitle>Para retomarlo completá el formulario.</CustomSubTitle>

        <Actions>
          <ButtonWatcher onClick={handleButton}>Ir al formulario</ButtonWatcher>
          <CustomLink
            to={`/first-step/1${document.location.search}`}
            onClick={handleLink}
          >
            Registrarme con otro mail
          </CustomLink>
        </Actions>
      </SubContainer>
    </Container>
  );
};

const Image = styled.img`
  display: flex;
  margin: 2rem auto;
  max-width: 100%;
`;

const CustomTitle = styled(Title)`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #3a3a3a;
  width: 100%;
`;

const CustomSubTitle = styled(Subtitle)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3a3a3a;
  width: 100%;
  margin: 0;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;

  div {
    display: block;
    width: 312px;
    z-index: unset;
    bottom: unset;
    position: relative;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: #3564fd;
`;

export default ResumeRegistration;
