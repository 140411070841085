import styled from "styled-components";

const LeftArrowContainer = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;

  img {
    width: 28px;
    height: 28px;

    :hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 25px;
    img {
      width: 25px;
      height: 25px;
    }
  }
`;

export { LeftArrowContainer };
