import Cookies from 'js-cookie';
import { ISignupFieldsInterface } from '../../features/AccountRegistration/types';
// import { formJSON } from '../../second-step-mock';
import { secondStepInstance } from '../../shared/axios';
import { endpoints } from '../../shared/constants';

const getSecondStepFields = async (
  country: string,
  product: string
): Promise<ISignupFieldsInterface> => {
  const params = new URLSearchParams([
    ['country', country],
    ['product', product],
  ]);
  const response = await secondStepInstance.get(endpoints.SECOND_STEP, {
    params,
  });
  return response.data;
  // return formJSON;
};

const getProvinciesByTaxType = (data: any) => {
  if (data.taxType === 'Multilateral') {
    return data.selectedProvincies;
  } else if (data.taxType === 'Exento') {
    return [];
  } else {
    return [data.taxJurisdiction];
  }
};

const postCompanyInfo = async (country: string, userData: any) => {
  const body = buildJsonBody({ country, ...userData });

  const response = await secondStepInstance.post(endpoints.SECOND_STEP, body, {
    headers: {
      Authorization: Cookies.get('sessionToken'),
    },
  });
  return response.data;
};

const buildJsonBody = (data: any) => {
  return {
    organization: {
      cuit: data.cuit,
      businessName: data.businessName,
      businessType: data.businessType,
      phoneNumber: data.phoneNumber,
      address: {
        zipCode: parseInt(data.zipcode, 10),
        province: data.organizationProvince,
      },
      taxType: data.grossEarnings ? data.taxType : undefined,
      taxNumber:
        data.grossEarnings && data.taxType !== 'Exento' ? data.taxNumber : '',
      taxJurisdiction: data.grossEarnings ? getProvinciesByTaxType(data) : [],
      obligatedSubject: data.obligatedSubject,
      FATCACountry: data.foreignTaxesLast ? data.fatcaCountriesLast : '',
    },
    representatives: [
      {
        documentId: data.documentId,
        jobTitle: data.jobTitle,
        obligatedSubject: data.obligatedSubject,
        pep: data.pep,
        FATCACountry: data.foreignTaxes ? data.fatcaCountries : '',
      },
    ],
  };
};

export { getSecondStepFields, postCompanyInfo };
