import { InputElement } from './types';
import PasswordInput from '../../components/CustomInputs/password';
import SelectInput from '../../components/CustomInputs/select';
import TextInput from '../../components/CustomInputs/string-input';
import SwitchInput from '../../components/CustomInputs/switch';
import CheckboxInput from '../../components/CustomInputs/checkbox';
import MultiSelect from '../../components/CustomInputs/multiselect';
import { UseFormReturn } from 'react-hook-form';
import { ErrorResponse } from '../../services/signup/types';

export const ERROR_CODES = {
  EMAIL_ALREADY_REGISTERED: '1001',
};

export const getErrorMessageByType = (error: ErrorResponse) => {
  if (error.code === ERROR_CODES.EMAIL_ALREADY_REGISTERED) {
    return 'Este mail ya está asociado a otra cuenta Ualá.';
  }

  return 'Ocurrió un error. Por favor, volvé a intentar.';
};

export const renderInputByType = (
  input: InputElement,
  index: number,
  formMethods: UseFormReturn
) => {
  if (!!input.renderIf && formMethods.watch(input.renderIf) === false) {
    return null;
  }
  switch (input.type) {
    case 'string':
    case 'number':
    case 'email':
      return <TextInput key={`${input.name}-${index}`} {...input} />;
    case 'password':
      return <PasswordInput key={`${input.name}-${index}`} {...input} />;
    case 'options':
      return <SelectInput key={`${input.name}-${index}`} {...input} />;
    case 'switch':
      return <SwitchInput key={`${input.name}-${index}`} {...input} />;
    case 'checkbox':
      return <CheckboxInput key={`${input.name}-${index}`} {...input} />;
    case 'multiselect':
      return <MultiSelect key={`${input.name}-${index}`} {...input} />;
    default:
      return null;
  }
};
