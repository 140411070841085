import { Switch } from "@uala/ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { InputElement } from "../../features/AccountRegistration/types";

const SwitchInput: React.FC<InputElement> = (
  { name, placeholder, type, description, options = [], validations },
  props: any
) => {
  const { register } = useFormContext();

  return (
    <MainContainer>
      <LabelContainer>
        <p>{placeholder}</p>
        {description && <LabelDescription>{description}</LabelDescription>}
      </LabelContainer>

      <SwitchContainer>
        <p>No</p>
        <Switch
          id={name}
          {...register(name)}
          type={type}
          size="normal"
          edge="end"
        />
        <p>Sí</p>
      </SwitchContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 75% 25%;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
`;

const LabelDescription = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    padding-right: 50px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-end;
  p {
    margin-right: 4px;
    margin-left: 4px;
  }
`;
export default SwitchInput;
