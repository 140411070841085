import React from 'react';
import styled from 'styled-components';
import BottomGradientBackgroundImage from '../../assets/images/bottom-gradient-background.svg';
import UalaBisLogoImage from '../../assets/images/uala-bis.svg';
import { HomeContainer } from './styles';
import BenefitsList from '../../components/UI/BenefitsList/BenefitsList';

const HomeDesktop: React.FC = () => {
  return (
    <HomeContainer>
      <BackgroundContainer>
        <ContentContainer>
          <UalaBisLogo src={UalaBisLogoImage} />
          <Title>Usá la app de Ualá Bis para tu empresa</Title>
          <BenefitsList>
            <li>
              Recibí la plata en el acto en tu cuenta gratuita con la app de
              Ualá.
            </li>
            <li>Aceptá todas las tarjetas y ofrecé cuotas a tus clientes.</li>
            <li>Pagá comisiones bajas, bajas en serio.</li>
          </BenefitsList>
        </ContentContainer>
        <UalaBisImagesContainer>
          <UalaBisProductsContainer>
            <UalaBisProducts src="/images/mpos-desktop.png" />
            <BottomGradient src={BottomGradientBackgroundImage} />
          </UalaBisProductsContainer>
        </UalaBisImagesContainer>
        <TopGradient />
      </BackgroundContainer>
    </HomeContainer>
  );
};

export default HomeDesktop;

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  z-index: 1;
`;

const UalaBisLogo = styled.img`
  margin: 40px 0px 0px 0px;
  width: 150px;
`;

const Title = styled.h1`
  margin: 40px 0px 0px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  color: #3a3a3a;
  width: 100%;
`;

const UalaBisImagesContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: flex-end;
`;

const UalaBisProductsContainer = styled.div`
  justify-content: center;
`;

const UalaBisProducts = styled.img`
  position: relative;
  z-index: 2;
  top: 10px;
`;

const TopGradient = styled.div`
  position: absolute;
  width: 100%;
  background: linear-gradient(
    45deg,
    rgba(255, 254, 254, 1) 75%,
    rgba(255, 235, 235, 1) 100%
  );
  height: 100%;
`;

const BottomGradient = styled.img`
  position: absolute;
  left: 50px;
  top: 100px;
`;
