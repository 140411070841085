import React from 'react'
import LandingContainer from '../../components/Layout/LandingContainer'
import { Illustration, Title, Text, Button } from '@uala/abra'
import { useHistory } from 'react-router-dom'
import { useGetDevice } from '../../hooks/useDesktopSize'
import { ONE_LINK_URL } from '../../shared/constants'
import useAmplitudEvent from '../../hooks/useAmplitudEvent'
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider'

const Approved = () => {
  const device = useGetDevice();
  const history = useHistory()
  useAmplitudEvent('registro_ppjj_registro_finalizado', { reason: 'aprobado' })

  const onClick = () => {
    createAmplitudeEvent('registro_ppjj_comenzar_operar', { device, source: 'aprobado' })
    device === 'mobile' ? window.location.assign(ONE_LINK_URL) : window.open("/documents/crear-cuenta-Uala-desktop.pdf")
  }

  return (
    <LandingContainer showNavBar showBackButton backButtomFunction={() => history.push('/first-step/1')}>
      <Illustration name='large-exito-fotografia-selfie-documento' />
      <Title align='center'>Tenés un registro finalizado</Title>
      <Text size='lg' align='center' css={{ maxWidth: 320 }}>
        Ya podés cobrar como persona jurídica ingresando a la app.
      </Text>
      <Button
        isBlock
        as='button'
        onClick={onClick}
        css={{ marginTop: 'auto', marginBottom: '$32', textDecoration: 'none' }}
      >
        Comenzar a operar
      </Button>
    </LandingContainer>
  )
}

export default Approved