import { omit, pick } from '@styled-system/props';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Option } from '../../features/AccountRegistration/types';
import {
  StyledOutlinedSelect,
  StyledSelect,
  StyledSelectBase,
  StyledSelectProps,
} from './styles';

export type SelectProps = {
  id: string;
  label: string;
  type?: string;
  variant?: 'standard' | 'outlined';
  options: Option[];
  rightAddon?: React.ReactElement;
} & React.InputHTMLAttributes<HTMLInputElement> &
  StyledSelectProps;

const selectVariants = {
  standard: StyledSelect,
  outlined: StyledOutlinedSelect,
};

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      id,
      label,
      rightAddon,
      variant = 'standard',
      options,
      name = '',
      onChange,
      onBlur,
      value,
      ...rest
    },
    ref
  ) => {
    const wrapperProps = pick(rest);
    const inputProps = omit(rest);

    const SelectComponent = selectVariants[variant];

    const {
      formState: { errors },
    } = useFormContext();
    return (
      <SelectComponent {...wrapperProps} error={Boolean(errors[name])}>
        <label htmlFor={name}>{label}</label>
        <StyledSelectBase
          defaultValue=""
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          {...inputProps}
        >
          <option disabled></option>
          {options.map((option, index) => {
            return (
              <option value={option.value} key={`${name}-${index}`}>
                {option.label}
              </option>
            );
          })}
          {rightAddon}
        </StyledSelectBase>
        {errors[name] && <span>{errors[name]?.message}</span>}
      </SelectComponent>
    );
  }
);

export default Select;
