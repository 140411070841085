const ERROR_CODES = {
  CUIT_ALREADY_REGISTERED: '1016',
};

export const getErrorMessageByType = (error: any) => {
  let message = '';
  switch (error?.code) {
    case ERROR_CODES.CUIT_ALREADY_REGISTERED:
      message =
        'El CUIT que ingresaste ya está registrado, revisá los datos o contactate con la sección de ayuda.';
      break;
    default:
      message = 'Algo salió mal, por favor intente de nuevo.';
      break;
  }
  return message;
};
