import React from 'react';
import LandingContainer from '../../components/Layout/LandingContainer';
import { Link, Illustration, Title, Text, Button } from '@uala/abra';
import {
  createAmplitudeEvent,
  setUserProp,
} from '../contexts/AmplitudeProvider';
import { useHistory } from 'react-router-dom';
import RejectedState from '../../shared/types/rejectedState';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';
import Cookies from 'js-cookie';
import { useGetDevice } from '../../hooks/useDesktopSize';

const RejectedPepSo = () => {
  const device = useGetDevice();

  sessionStorage.removeItem('user');
  Cookies.remove('sessionToken');
  const {
    location: { state },
    goBack,
  } = useHistory<RejectedState>();
  setUserProp('user_type', 'COMPANY');
  let rejectedReason = state ? '' : 'PEP y/o sujeto obligado';
  let amplitudeRejectedReason = state ? '' : 'compliance';
  if (state?.pep) {
    rejectedReason = 'PEP';
    amplitudeRejectedReason = 'pep';
  }
  if (state?.obligatedSubject) {
    if (state.pep) {
      rejectedReason += ' y ';
      amplitudeRejectedReason += ', ';
    }
    rejectedReason += 'sujeto obligado';
    amplitudeRejectedReason += 'so';
  }
  useAmplitudEvent('registro_ppjj_finaliza_registro', {
    success: false,
    reason: amplitudeRejectedReason,
  });

  return (
    <LandingContainer
      showNavBar
      showBackButton={!state}
      backButtomFunction={() => {
        goBack();
      }}
    >
      <Illustration name="large-contrato-documentacion-encuesta" />
      <Title align="center">
        Necesitamos que nos <br /> contactes
      </Title>
      <Text size="lg" align="center" css={{ maxWidth: 320 }}>
        Como indicaste que sos{' '}
        <Text as="span" weight="bold">
          {rejectedReason},
        </Text>{' '}
        envianos tu CUIT y tu email registrado a{' '}
        <Link>hola@ualabis.com.ar</Link> para completar el registro.
      </Text>
      <Button
        isBlock
        as="a"
        onClick={() =>
          createAmplitudeEvent('registro_ppjj_contacta', {
            device,
            source: 'compliance',
            contacto: 'mail',
          })
        }
        href={`mailto:hola@ualabis.com.ar?subject=Registro pendiente por ${rejectedReason}`}
        css={{ marginTop: 'auto', marginBottom: '$32', textDecoration: 'none' }}
      >
        Enviar email
      </Button>
    </LandingContainer>
  );
};

export default RejectedPepSo;
