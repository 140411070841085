import styled from 'styled-components';

export const HomeContainer = styled.div`
  display: flex;
  background-size: 100%;
  align-items: start;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  justify-content: left;
  background-color: #fafafa;  
  @media (min-width: 1200px) {
    width: 60%;
  }
`;

export const HomeMobileContainer = styled(HomeContainer)`
  min-width: auto;
  margin: 0 auto;
  padding: 0;
  overflow: unset;
  max-height: unset;
  width: 100%;
  background-color: #ffffff;
`;
