import styled from 'styled-components';
import {
  FormContainer,
  Subtitle,
  Title,
} from '../AccountRegistration/styles';
import { PinInput } from '@uala/ui';


const VerificationFormContainer = styled(FormContainer)`
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 25px 5px 25px 5px;
  margin-bottom: 0px;
`;

const VerificationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

interface IResendButton {
  isDisabled: boolean;
}

const StyledPinInput = styled(PinInput)`
  & input {
    max-width: 50px;
    max-height: 50px;
    width: 50px;
    height: 50px;
    background-color: #ededed;
    border: none;

    :last-of-type {
      margin-right: 0px;
    }

    @media (max-width: 1200px) {
      width: 48px;
      height: 48px;
      margin-right: 7px;
    }
    @media (max-width: 480px) {
      width: 46px;
      height: 46px;
      margin-right: 5px;
    }
    @media (max-width: 375px) {
      width: 40px;
      height: 40px;
      margin-right: 3px;
    }
  }
`;

const ResendButton = styled.button<IResendButton>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: ${(props) => (props.isDisabled ? '#bdbdbd' : '#3564FD')};
  text-align: center;
  justify-content: flex-end;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: auto auto 40px;
  @media (max-width: 1200px) {
    margin-top: 20px;
  }
`;

const VerificationTitle = styled(Title)`
  @media (max-width: 480px) {
    padding-left: 25px;
  }
  @media (min-width: 480px) {
    align-self: center;
  }
`;

const VerificationSubtitle = styled(Subtitle)`
  margin: 5px 0px 30px 0px;
  align-self: center;
`;

export {
  VerificationContainer,
  VerificationFormContainer,
  ResendButton,
  VerificationTitle,
  VerificationSubtitle,
  StyledPinInput,
};
