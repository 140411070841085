import styled from 'styled-components';

interface PreviewProps {
  error: boolean;
  placeholder: string;
  value: string | null;
}

const PreviewInput = ({ error, placeholder, value }: PreviewProps) => {
  return (
    <>
      <P error={error}>{value ?? placeholder}</P>
      <Label error={error}>{value ? placeholder : ''}</Label>
      <Span>
        {error
          ? ' Debe seleccionar mas de una provincia, de lo contrario seleccione tipo de régimen local'
          : ''}
      </Span>
    </>
  );
};

const P = styled.p<{ error: boolean }>`
  font-family: inherit;
  width: 100%;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-bottom-style: solid;
  border-left-style: initial;
  border-color: ${(props) => (props.error ? '#ff8382' : '#bdbdbd')};
  border-image: initial;
  border-radius: 0px;
  outline: 0px;
  font-size: 1rem;
  color: #3a3a3a;
  line-height: 1.3rem;
  padding: 5px 0px;
  background: transparent;
  transition: border-color 0.2s ease 0s;
  margin: 0px;
`;

const Label = styled.label<{ error: boolean }>`
  position: absolute;
  top: 0px;
  display: block;
  transition: all 0.2s ease 0s;
  font-size: 0.75rem;
  line-height: 1.33;
  color: ${(props) => (props.error ? '#d34040' : '#3a3a3a')};
`;

const Span = styled.span`
  align-self: flex-start;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
  color: #d34040;
  margin-top: 3px;
  display: block;
`;

export default PreviewInput;
