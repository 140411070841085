import React from 'react';
import { Footer, SnackbarProvider, ThemeProvider } from '@uala/ui';
import { QueryClientProvider } from 'react-query';
import queryClient from './config/queryClient';
import GlobalStyles from './utils/globalStyles';
import styled from 'styled-components';
import { useGetDevice } from './hooks/useDesktopSize';
import UserDataProvider from './features/contexts/userContext';
import { Amplify } from 'aws-amplify';
import AmplifyConfig from './amplify.config';
import AppStoreIcon from './components/Footer/AppStoreIcon';
import GooglePlayIcon from './components/Footer/GooglePlayIcon';
import Commissions from './features/Commissions';
import Split from './components/Split';
import AmplitudeProvider from './features/contexts/AmplitudeProvider';
import { AbraProvider } from '@uala/abra';
import Routes from './Routes';
import HomeDesktop from './features/Home/HomeDesktop';

Amplify.configure(AmplifyConfig);

const App: React.FC = () => {
  const device = useGetDevice();
  const footer = {
    title:
      'Si no sos Persona Jurídica, descargá la app de Ualá y completá el registro en tu celular para empezar a cobrar con Ualá Bis.',
    stores: {
      appStore: {
        icon: AppStoreIcon,
        link:
          process.env.REACT_APP_APP_STORE_LINK ??
          'https://apps.apple.com/ar/app/ual%C3%A1/id1279808159',
      },
      googlePlay: {
        icon: GooglePlayIcon,
        link:
          process.env.REACT_APP_GOOGLE_PLAY_LINK ??
          'https://play.google.com/store/apps/details?id=ar.com.bancar.uala',
      },
    },
  };

  return (
    <ThemeProvider>
      <AbraProvider>
        <SnackbarProvider>
          <UserDataProvider>
            <QueryClientProvider client={queryClient}>
              <GlobalStyles />
              <Container>
                <Split>
                  <AmplitudeProvider>
                    <>
                      {device === 'desktop' && <HomeDesktop />}
                      <Routes footer={footer} />
                      {device === 'desktop' && (
                        <Footer title={footer.title} stores={footer.stores} />
                      )}
                    </>
                  </AmplitudeProvider>
                </Split>
              </Container>
              {device === 'desktop' && <Commissions />}
            </QueryClientProvider>
          </UserDataProvider>
        </SnackbarProvider>
      </AbraProvider>
    </ThemeProvider>
  );
};

export default App;

const Container = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 700px;

  @media (max-width: 1200px) {
    min-height: unset;
  }

  footer {
    z-index: 2;
    
    div hr {
    opacity: 0;
    margin: 0 12px;

  }

  @media (max-width: 480px) {
    box-sizing: border-box;
    padding: 0;
  }
`;
