import { Box, Button } from '@uala/ui';
import styled from 'styled-components';

const RightContainer = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  @media (min-width: 1200px) {
    width: 40%;
  }
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: start;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 250px;
  margin: 0 30px auto;
  @media (max-width: 1200px) {
    max-width: 100%;
    margin-top: 64px;
    height: initial;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 250px;
  max-width: 400px;
  margin: 60px 16px auto;
  @media (max-width: 1200px) {
    max-width: 100%;
    margin: 20px 16px auto;
  }
`;

const FormContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  align-self: flex-start;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #3a3a3a;
`;

const Subtitle = styled.h2`
  align-self: flex-start;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #3a3a3a;
`;

const SubmitButton = styled(Button)`
  max-width: 342px;
  width: 100%;
  height: 48px;
  border-radius: 30px;
  margin: 24px auto;
  @media (max-width: 342px) {
    max-width: 90%;
  }
`;

const SkeletonContainer = styled.div`
  width: 100%;
`;

export {
  RightContainer,
  FormContainer,
  Title,
  Subtitle,
  Container,
  SubmitButton,
  SkeletonContainer,
  SubContainer,
};
