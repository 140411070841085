import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputElement } from '../../features/AccountRegistration/types';
import Select from '../Select';

const SelectInput: React.FC<InputElement> = (
  { name, placeholder, options = [], validations },
  props: any
) => {
  const [rendered, setRendered] = useState(false);
  const { register, watch, resetField, setValue } = useFormContext();
  const watchTaxType = watch('taxType') ? watch('taxType') : 'Local';
  const watchGrossEarnings = watch('grossEarnings', false);

  useEffect(() => {
    if (['taxJurisdiction'].some((field) => name === field)) {
      if (watchGrossEarnings && watchTaxType === 'Local') {
        setRendered(true);
      } else {
        resetField('taxJurisdiction');
        setRendered(false);
      }
    } else {
      setRendered(true);
    }
    if (['taxType'].some((field) => name === field)) {
      if (watchGrossEarnings) {
        if (!watchTaxType || watchTaxType === '') {
          setValue('taxType', 'Local');
        } else {
          setValue('taxType', watchTaxType);
        }
      }
    }
    if (!watchGrossEarnings) {
      resetField('taxType');
      resetField('taxNumber');
      resetField('taxJurisdiction');
      resetField('taxJurisdictionMultiple');
    } else if (watchTaxType === 'Exento') {
      resetField('taxJurisdiction');
      resetField('taxJurisdictionMultiple');
    } else if (watchTaxType === 'Local') {
      resetField('taxJurisdictionMultiple');
    } else if (watchTaxType === 'Multilateral') {
      resetField('taxJurisdiction');
    }
  }, [watchGrossEarnings, watchTaxType]);

  return (
    <>
      {rendered && (
        <Select
          width="100%"
          {...register(name, validations)}
          label={placeholder}
          options={options}
          {...props}
        />
      )}
    </>
  );
};

export default SelectInput;
