import React from 'react';
import styled from 'styled-components';

interface Item {
  title: string;
  text: string;
  subText: string;
}

interface Props {
  title: string;
  items: Array<Item>;
  small?: boolean;
}

const CommisionCard = ({ title, items, small = false }: Props) => (
  <CommisionCardBody itemLength={items.length} small={small}>
    <CommisionTitle itemLength={items.length}>{title}</CommisionTitle>
    {items.map(({ title, text, subText }) => (
      <div key={`commision-card-${title}`}>
        <CommisionSubTitle>{title}</CommisionSubTitle>
        <CommisionText>
          {text}
          <CommisionSubText>{subText}</CommisionSubText>
        </CommisionText>
      </div>
    ))}
  </CommisionCardBody>
);

const CommisionCardBody = styled.div<{ itemLength: number; small: Boolean }>`
  border-radius: 27px;
  padding: 2rem;
  margin: 1rem 2rem 1rem 0;
  background-color: white;
  width: -webkit-fill-available;
  box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: repeat(${(props) => props.itemLength}, 1fr);
  grid-template-rows: repeat(${(props) => props.itemLength}, 1fr);
  grid-column-gap: 28px;
  grid-row-gap: 0px;
  grid-template-rows: auto 1fr;
  max-width: ${(props) => (props.small ? 'min-content' : '400px')};

  @media (max-width: 1200px) {
    margin: 1rem auto;
    max-width: 400px;
  }
  @media (max-width: 500px) {
    margin: 1rem;
  }
`;

const CommisionTitle = styled.h3<{ itemLength: number }>`
  grid-column-start: 1;
  grid-column-end: ${(props) => props.itemLength + 1};
  color: #3e6bfd;
  margin-top: 0px;
`;

const CommisionSubTitle = styled.div`
  color: #668aff;
  border-bottom: 1px solid #668aff;
  padding-bottom: 5px;
  white-space: nowrap;
`;

const CommisionText = styled.p`
  font-size: 2rem;
  text-align: start;
  margin-top: 0.2rem;
  margin-bottom: 0;
  font-weight: bold;
  color: #636363;

  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
`;

const CommisionSubText = styled.span`
  font-size: 1rem;
  font-weight: normal;
  margin: auto 5px auto 0;
`;

export default CommisionCard;
