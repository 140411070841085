import React from 'react';
import { Icon, Stack, Text } from '@uala/abra';

type InputCheckProps = {
  isValid: boolean;
  text: string;
};

const InputCheck = ({ isValid = false, text }: InputCheckProps) => {
  return (
    <Stack spacing="8" align="center">
      <Icon
        color={isValid ? 'success' : 'default'}
        name={isValid ? 'check-circle-fill' : 'check-circle-empty'}
      />
      <Text css={{ fontSize: '$3' }}>{text}</Text>
    </Stack>
  );
};

export default InputCheck;
