import React, { createContext, FC, useState } from 'react';
import IUser from '../../shared/types/user';

type UserData = {
  user: Partial<IUser> | null;
  setUser: (user: IUser, persisUser?: boolean) => void;
};

export const UserDataContext = createContext<UserData>({
  user: null,
  setUser: (_: IUser) => {},
});

const UserDataProvider: FC = ({ children }): React.JSX.Element => {
  const [userState, setUserState] = useState<IUser>(
    JSON.parse(sessionStorage.getItem('user') ?? '{}') as IUser
  );

  const setUser = (newUserData: IUser, persisUser = false) => {
    const userData = Object.assign(persisUser ? userState : {}, newUserData);
    sessionStorage.setItem('user', JSON.stringify(userData));
    setUserState(userData);
  };

  return (
    <UserDataContext.Provider value={{ user: userState, setUser }}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
