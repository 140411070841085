import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  resendVerificationCodeByEmail,
  signInUserToCognito,
  validateUserSignupCode,
} from '../../services/userVerification';
import { UserDataContext } from '../contexts/userContext';
import {
  VerificationFormContainer,
  VerificationSubtitle,
  VerificationTitle,
  ResendButton,
  StyledPinInput,
  VerificationContainer,
} from './styles';
import { useSnackbar } from '@uala/ui';
import { getErrorMessageByType } from './utils';
import FormContainer from '../../components/Layout/FormContainer';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { getEventNameByPathname } from '../../utils/amplitude';
import LoadingScreen from '../../components/UI/LoadingScreen';
import useGtmEvent, { useGetGtmSubSectionName } from '../../hooks/useGtmEvent';

const AccountVerification: React.FC = () => {
  const codeLength = 6;
  const { user } = useContext(UserDataContext);
  const history = useHistory();
  const addSnackbar = useSnackbar();
  const device = useGetDevice();
  const sendGtmEvent = useGtmEvent()
  const subSectionName = useGetGtmSubSectionName()

  const [shouldWaitResend, setShouldWaitResend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(60);

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (timer < 1) {
      setShouldWaitResend(false);
      return;
    }

    const intervalId = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer, shouldWaitResend]);

  const handleChange = async (code: string) => {
    setValue(code);
    if (code.length === codeLength) {
      try {
        setIsLoading(true);
        const response = await validateUserSignupCode(
          code,
          user?.accountEmail!
        );
        createAmplitudeEvent(getEventNameByPathname(), {
          device,
          success: response.status !== 'ERROR',
        });
        if (response.status === 'ERROR') {
          addSnackbar(
            'El código ingresado es invalido. Por favor solicita uno nuevo.'
          );
          setValue('');
          setIsLoading(false);
          return;
        }
        sendGtmEvent('u.operation', {
          'flow': 'registro empresas',
          'step': subSectionName,
          'step_number': 3
        });
        setIsLoading(false);
        await signInUserToCognito(user?.accountEmail!, user?.password!);
        history.push('/second-step/1');
      } catch (error: any) {
        setValue('');
        addSnackbar(getErrorMessageByType(error.response?.data?.code));
        setIsLoading(false);
      }
    }
  };

  const handleResend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      sendGtmEvent('u.operation', {
        'subsection': subSectionName,
        'action': 'seleccionar',
        'element': e.currentTarget.innerText
      })
      createAmplitudeEvent('registro_ppjj_reenvia_codigo_email', { device });
      await resendVerificationCodeByEmail(user?.accountEmail!);
      setValue('');
      setTimer(60);
      setIsLoading(false);
      setShouldWaitResend(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer>
      <VerificationContainer>
        <VerificationFormContainer method="POST">
          <VerificationTitle>
            Ingresá el código que te enviamos al email:
          </VerificationTitle>
          <VerificationSubtitle>{user?.accountEmail}</VerificationSubtitle>
          <StyledPinInput
            id="pin-input"
            length={codeLength}
            type="number"
            value={value}
            onChange={handleChange}
            disabled={isLoading}
            autoFocus
            required
          />
        </VerificationFormContainer>
        {isLoading && <LoadingScreen />}
        <ResendButton
          type="button"
          onClick={handleResend}
          disabled={shouldWaitResend || isLoading}
          isDisabled={shouldWaitResend}
        >
          Reenviar código{' '}
          {shouldWaitResend && `(00:${timer.toString().padStart(2, '0')})`}
        </ResendButton>
      </VerificationContainer>
    </FormContainer>
  );
};

export default AccountVerification;
