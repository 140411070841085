import { Amplify } from "aws-amplify";

const AmplifyConfig = Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
});

export default AmplifyConfig;
