import React from 'react';
import styled from 'styled-components';
import FaqIcon from '../FaqIcon';
import UalaBisLogoImage from '../../../assets/images/uala-bis.svg';
import { IconButton } from '@uala/abra';

const Navbar = ({
  showFaq = false,
  showBackButton = false,
  backButtomFunction = () => {},
}) => {
  return (
    <MobileHeader>
      {showBackButton && (
        <IconButton
          label="back button"
          icon="left"
          onClick={backButtomFunction}
          css={{
            position: 'absolute',
            marginLeft: '10px',
          }}
        />
      )}

      <UalaLogo src={UalaBisLogoImage} />

      {showFaq && <FaqIcon />}
    </MobileHeader>
  );
};

export default Navbar;

const UalaLogo = styled.img`
  margin: auto;
`;

const MobileHeader = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #ffffff;
  position: fixed;
`;
