import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useGetDevice } from './hooks/useDesktopSize';
import HomeMobile from './features/Home/HomeMobile';
import AboutPpjj from './features/AboutPpjj';
import AccountVerification from './features/AccountVerification';
import Approved from './features/Approved';
import ApprovedPpff from './features/ApprovedPpff';
import CompanyInfo from './features/CompanyInfo';
import Completed from './features/Completed';
import PersonalInfo from './features/PersonalInfo';
import Rejected from './features/Rejected';
import RejectedPepSo from './features/RejectedPepSo';
import ResumeRegistration from './features/ResumeRegistration';
import SelectPersonType from './features/SelectPersonType';
import ApprovedPpffNoBis from './features/ApprovedPpffNoBis';
import CreateAccount from './features/CreateAccount';

const Routes = ({ footer }: { footer: object }) => {
  const device = useGetDevice();
  const { location } = window;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          {device === 'desktop' && (
            <Redirect
              to={{
                pathname: '/select-person-type',
                search: location.search,
              }}
            />
          )}
          {device === 'mobile' && (
            <Redirect
              to={{ pathname: '/home/mobile', search: location.search }}
            />
          )}
        </Route>
        <Route exact path="/home/mobile">
          {device === 'desktop' && (
            <Redirect to={{ pathname: '/', search: location.search }} />
          )}
          <HomeMobile footer={footer} />
        </Route>
        <Route path="/first-step/:screenId">
          <CreateAccount />
        </Route>
        <Route path="/verify">
          <AccountVerification />
        </Route>
        <Route path="/second-step/:screenId">
          <CompanyInfo />
        </Route>
        <Route path="/completed">
          <Completed />
        </Route>
        <Route path="/select-person-type">
          <SelectPersonType />
        </Route>
        <Route path="/resume-registration">
          <ResumeRegistration />
        </Route>
        <Route path="/personal-info">
          <PersonalInfo />
        </Route>
        <Route path="/about-ppjj">
          <AboutPpjj />
        </Route>
        <Route path="/rejected-compliance">
          <RejectedPepSo />
        </Route>
        <Route path="/approved">
          <Approved />
        </Route>
        <Route path="/approved-ppff">
          <ApprovedPpff />
        </Route>
        <Route path="/approved-ppff-no-bis">
          <ApprovedPpffNoBis />
        </Route>
        <Route path="/rejected">
          <Rejected />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
