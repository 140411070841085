import { useContext, useEffect, useState } from 'react';
import { AmplitudeContext, createAmplitudeEvent } from '../features/contexts/AmplitudeProvider';
import { useGetDevice } from './useDesktopSize';
import useWindowSize from './useWindowSize';

const useAmplitudEvent = (eventName: string, eventParams: object = {}) => {
  const { width } = useWindowSize();
  const AmplitudData = useContext(AmplitudeContext);
  const [eventSend, setEventSend] = useState(false);
  const device = useGetDevice();
  useEffect(() => {
    if (AmplitudData.isInitiated && width > 0 && !eventSend) {
      createAmplitudeEvent(eventName, { device, ...eventParams });
      setEventSend(true);
    }
  }, [AmplitudData.isInitiated, width]);
  return eventSend;
};

export default useAmplitudEvent;
