import { useContext, useEffect, useState } from 'react';
import { Input, RowList, Row, Stack, Switch, Link } from '@uala/abra';
import { useGetDevice } from '../../hooks/useDesktopSize';
import FormContainer from '../../components/Layout/FormContainerV2';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from '../../components/UI/Select';
import countries from '../../shared/constants/countries.json';
import { UserDataContext } from '../contexts/userContext';
import {
  AmplitudeContext,
  createAmplitudeEvent,
  setAmplitudUserId,
} from '../contexts/AmplitudeProvider';
import { ErrorResponse } from '../../services/signup/types';
import { getErrorMessageByType } from '../AccountRegistration/utils';
import useGtmEvent from '../../hooks/useGtmEvent';
import { getRedirectPathByStatus } from './utils';
import { postSignupUser } from '../../services/signup';
import Password from '../../components/UI/Inputs/Password';
import PasswordInput from './PasswordInput';
import { getRegistrationStatus } from '../../services/user';
import { AccountStatus } from '../../shared/types/account';

const EMAIL_SCREEN = 1;
const PASS_SCREEN = 2;
const PERSONAL_DATA_SCREEN = 3;

const CreateAccount = () => {
  let { screenId } = useParams<{ screenId: '1' | '2' | '3' }>();
  const device = useGetDevice();
  const history = useHistory();
  const location = useLocation();
  const formMethods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<undefined | string>();
  const { setUser } = useContext(UserDataContext);
  const sendGtmEvent = useGtmEvent();
  const AmplitudeData = useContext(AmplitudeContext);
  const [registerStatus, setRegisterStatus] = useState<
    AccountStatus | undefined
  >();

  const screenNumber = parseInt(screenId) as
    | typeof EMAIL_SCREEN
    | typeof PASS_SCREEN
    | typeof PERSONAL_DATA_SCREEN;
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (AmplitudeData.isInitiated && screenNumber === EMAIL_SCREEN) {
      createAmplitudeEvent('registro_ppjj_visualiza_primer_paso', {
        source: 'first-step/1',
        device,
      });
    }
  }, [AmplitudeData.isInitiated, screenNumber]);

  useEffect(() => {
    setErrorText(undefined);
  }, [location]);

  const wording = {
    [EMAIL_SCREEN]: {
      title: 'Crear cuenta',
      subTitle: 'Ingresá un email para comenzar.',
    },
    [PASS_SCREEN]: { title: 'Crear una contraseña', subTitle: null },
    [PERSONAL_DATA_SCREEN]: {
      title: 'Ingresá los datos de la persona apoderada',
      subTitle: null,
    },
  };

  const onSubmit = async (data: any) => {
    switch (screenNumber) {
      case EMAIL_SCREEN:
        setIsLoading(true);
        sendGtmEvent('u.operation', {
          flow: 'registro empresas',
          step: 'usuario y contraseña',
          step_number: screenNumber,
        });
        try {
          const { status, userType } = await getRegistrationStatus(data.email);
          setIsLoading(false);
          setRegisterStatus(status);
          createAmplitudeEvent('registro_ppjj_completa_usuario', {
            device,
            success: true,
          });
          history.push(getRedirectPathByStatus(status, userType));
          return;
        } catch (error) {
          createAmplitudeEvent('registro_ppjj_completa_usuario', {
            device,
            success: false,
          });
          setErrorText('Ocurrió un error. Por favor, volvé a intentar.');
          setIsLoading(false);
          return;
        }
      case PASS_SCREEN:
        createAmplitudeEvent('registro_ppjj_completa_contraseña', {
          device,
          core_status: registerStatus,
        });
        break;
    }

    if (screenNumber < PERSONAL_DATA_SCREEN) {
      history.push(`/first-step/${screenNumber + 1}`);
      return;
    }

    // * for use in rejected email template
    setUser({ accountEmail: data.email });
    try {
      setIsLoading(true);
      setErrorText(undefined);
      const accountId = await postSignupUser(data);

      setAmplitudUserId(accountId);
      sendGtmEvent('u.operation', {
        flow: 'registro empresas',
        step: 'datos de la persona apoderada',
        step_number: screenNumber,
      });
      setUser(
        {
          accountId,
          accountEmail: data.email,
          password: data.password,
          documentId: data.cuil,

          // TODO: save this in first step and not persist anymore
          jobTitle: data.companyPosition,
          pep: data.pep,
          fatcaCountries: data.fatcaCountries,
          foreignTaxes: data.foreignTaxes,
        },
        true
      );
      setIsLoading(false);
      createAmplitudeEvent('registro_ppjj_selecciona_rol_empresa', {
        rol_empresa: data.companyPosition,
        pep: data.pep,
        impuestos_otro_pais: data.foreignTaxes,
        device,
        success: true,
      });
      history.push('/verify');
    } catch (error: any) {
      createAmplitudeEvent('registro_ppjj_selecciona_rol_empresa', {
        rol_empresa: data.companyPosition,
        pep: data.pep,
        impuestos_otro_pais: data.foreignTaxes,
        device,
        success: false,
      });
      const errorResponse = error.response?.data as ErrorResponse;

      setErrorText(getErrorMessageByType(errorResponse));
      setIsLoading(false);
    }
  };

  return (
    <FormContainer
      showNavBar
      showBackButton={screenNumber !== EMAIL_SCREEN && device === 'mobile'}
      showFaq
      backButtomFunction={() => history.push(`/first-step/${screenNumber - 1}`)}
      title={wording[screenNumber].title}
      subTitle={wording[screenNumber].subTitle}
      errorText={errorText}
      onSubmit={onSubmit}
      isLoading={isLoading}
      formMethods={formMethods}
    >
      <Stack direction="column" css={{ width: '100%', height: '100%' }}>
        {screenNumber === EMAIL_SCREEN && (
          <Input
            type="email"
            placeholder="Email"
            {...register('email', {
              required: 'El campo es requerido',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                message: 'Debe ingresar un email válido',
              },
            })}
            isInvalid={!!errors['email']}
            errorText={`${errors['email']?.message}`}
          />
        )}
        {screenNumber === PASS_SCREEN && (
          <>
            <PasswordInput />
            <Password
              name="repeatPassword"
              placeholder="Repetir contraseña"
              registerOptions={{
                required: 'El campo es requerido',
                validate: (inputValue: string, formValues: any) =>
                  inputValue === formValues.password ||
                  'Las contraseñas no coinciden',
              }}
              isInvalid={!!errors['repeatPassword']}
              errorText={`${errors['repeatPassword']?.message}`}
            />
          </>
        )}
        {screenNumber === PERSONAL_DATA_SCREEN && (
          <>
            <Input
              type="number"
              label="CUIL de la pesona apoderada"
              placeholder="CUIL"
              helpText="Solo números, sin guiones ni espacios."
              {...register('cuil', {
                required: 'El campo es requerido',
                minLength: {
                  value: 11,
                  message: 'El número de CUIL deber tener 11 caracteres.',
                },
                maxLength: {
                  value: 11,
                  message: 'El número de CUIL deber tener 11 caracteres.',
                },
                validate: (inputValue: number) =>
                  inputValue.toString().startsWith('2') ||
                  'El CUIL no corresponde a una persona.',
              })}
              isInvalid={!!errors['cuil']}
              errorText={`${errors['cuil']?.message}`}
            />
            <Link
              href="https://serviciosweb.afip.gob.ar/publico/cuitOnline/InfoPersonal.aspx"
              isInline
              target="_blank"
              size="sm"
              css={{
                justifyContent: 'flex-start',
                marginTop: '$4',
                marginBottom: '$12',
              }}
            >
              ¿Cómo se obtiene el CUIL?
            </Link>
            <Select
              label="Rol en la empresa"
              register={register('companyPosition', {
                required: 'El campo es requerido',
              })}
              isInvalid={!!errors['companyPosition']}
              options={[
                {
                  name: 'Apoderado',
                  value: 'Apoderado',
                },
                {
                  name: 'Presidente',
                  value: 'Presidente',
                },
                {
                  name: 'Director',
                  value: 'Director',
                },
                {
                  name: 'Administrador',
                  value: 'Administrador',
                },
                {
                  name: 'Socio Fundador',
                  value: 'Socio Fundador',
                },
                {
                  name: 'Socio',
                  value: 'Socio',
                },
              ]}
            />
            <RowList>
              <Row
                title="Persona políticamente expuesta (PEP)"
                description="¿Vos, algún familiar o persona allegada ocupan u ocuparon alguno de los cargos mencionados en la Resolución UIF 134/2018?"
                rightElement={
                  <Switch
                    {...register('pep', { value: false })}
                    onCheckedChange={(check) => setValue('pep', check)}
                  />
                }
              />
              <Row
                css={{ '& span': { whiteSpace: 'normal' } }}
                title="¿Pagás impuestos en otro país?"
                rightElement={
                  <Switch
                    {...register('foreignTaxes', { value: false })}
                    onCheckedChange={(check) => setValue('foreignTaxes', check)}
                  />
                }
              />
            </RowList>
            {watch('foreignTaxes') && (
              <Select
                label="¿En dónde?"
                register={register('fatcaCountries', {
                  required: 'El campo es requerido',
                })}
                isInvalid={!!errors['fatcaCountries']}
                options={countries}
              />
            )}
          </>
        )}
      </Stack>
    </FormContainer>
  );
};

export default CreateAccount;
