const eventList: { [index: string]: string } = {
  '/home/mobile': 'registro_ppjj_inicia_registro',
  '/personal-info': 'registro_ppjj_selecciona_comenzar',
  '/select-person-type': 'registro_ppjj_selecciona_persona',
  '/first-step/1': 'registro_ppjj_completa_usuario',
  '/first-step/2': 'registro_ppjj_selecciona_rol_empresa',
  '/verify': 'registro_ppjj_valida_email',
  '/second-step/1': 'registro_ppjj_completa_datos_empresa',
  '/second-step/2': 'registro_ppjj_completa_domicilio_empresa',
  '/second-step/3': 'registro_ppjj_completa_iibb',
  '/second-step/4': 'registro_ppjj_acepta_tyc',
  '/completed': 'registro_ppjj_finaliza_registro',
}

const getEventNameByPathname = () => {
  const { pathname } = window.location
  return eventList.hasOwnProperty(pathname) ? eventList[pathname] : ''
}

const getOptionsByPathname = (formData: any) => {
  const { pathname } = window.location
  const options: any = {};
  if (!!formData) {
    switch (pathname) {
      case '/first-step/2':
        options.rol_empresa = formData.companyPosition
        options.pep = formData.pep
        options.impuestos_otro_pais = formData.foreignTaxes
        break;
      case '/second-step/1':
        options.tipo_sociedad = formData.businessType
        break;
      case '/second-step/3':
        options.iibb = formData.grossEarnings
        options.tipo_iibb = formData.taxType
        break;
      case '/second-step/4':
        options.uif = formData.obligatedSubject
        options.impuestos_otro_pais = formData.foreignTaxesLast
        break;
    }
  }
  return options
}

const getOptionsByUrlSearch = (eventName: string) => {
  const { search } = window.location
  const options: any = {};
  const urlParams = new URLSearchParams(search)
  switch (eventName) {
    case 'registro_ppjj_visualiza_primer_paso':
    case 'registro_ppjj_landing_page_mobile':
      if (urlParams.has('af_sub1')) {
        options.referrer_id = urlParams.get('af_sub1')
      }
      break;
  }
  return options
}


export { getEventNameByPathname, getOptionsByPathname, getOptionsByUrlSearch }
