import { instance } from '../../shared/axios';
import { endpoints } from '../../shared/constants';
import { checkResponseStatus } from '../utils';
import { CreateAccountRequest } from './types';

const getParamByName = (name: string, params: any[]) => {
  const p =
    params.find((param) => param.name.toLowerCase() === name.toLowerCase())
      .value || '';
  return p;
};

const postSignupUser = async (userData: CreateAccountRequest) => {
  const requestBody = userData;
  if (!requestBody.foreignTaxes) {
    delete requestBody.fatcaCountries;
  }

  const { data } = await instance.post(endpoints.FIRST_STEP, requestBody);
  checkResponseStatus(data);

  const accountId = getParamByName('accountId', data.params);
  return accountId as string;
};

export { postSignupUser };
