import { AccountStatus, UserType } from '../../shared/types/account';

export const getRedirectPathByStatus = (
  status: AccountStatus,
  userType: UserType
) => {
  if (userType === 'NATURAL') {
    return '/resume-registration';
  }

  switch (status) {
    case 'ACTIVE':
    case 'VALID':
      return '/approved';
    case 'PENDING_COMPLIANCE_VALIDATION':
      return '/rejected-compliance';
    case 'PENDING_VALIDATION':
      return '/rejected';
    case 'CREATED':
    case 'NOT_STARTED':
      return '/first-step/2';
    default:
      return '/resume-registration';
  }
};
