import { Icon } from '@uala/abra';
import React from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown';
import styled from 'styled-components';

const CheckList = ({ children }: { children: ReactElement | ReactElement[] }) => {
  let items = Array.isArray(children) ? children : [children]
  return (
    <ListContainer>
      {items.map((item) => (
        <ListItem>
          <div>
            <Icon name='check-circle' size='md' color='primary' />
          </div>
          {item}
        </ListItem>
      ))}
    </ListContainer>
  )
}

const ListItem = styled.div`
  align-items: center;
`;

const ListContainer = styled.div`
  width: fit-content;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  div {
    display: flex;
    min-width: 24px;
    p {
      margin-left: 10px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
`;

export default CheckList