import React from 'react';
import LandingContainer from '../../components/Layout/LandingContainer';
import { Illustration, Title, Button, Stack, RowList, Row } from '@uala/abra';
import { useHistory } from 'react-router-dom';
import { useGetDevice } from '../../hooks/useDesktopSize';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { ONE_LINK_URL } from '../../shared/constants';

const ApprovedPpffNoBis = () => {
  const device = useGetDevice();
  const history = useHistory();
  useAmplitudEvent('registro_ppjj_ya_registrado_uala', { reason: 'no_bis' });

  return (
    <LandingContainer
      showNavBar
      showBackButton
      backButtomFunction={() => history.push('/first-step/1')}
    >
      <Illustration name="large-documento-y-selfie" />
      <Title align="center">Ya tenés una cuenta en Ualá</Title>
      <RowList css={{ marginTop: 24 }}>
        <Row
          icon="stores"
          description="Para comenzar a vender, tenés que ingresar a la sección Cobrar en la app."
        />
        <Row
          icon="mail"
          description="Si sos persona jurídica, usá otro email para registrarte."
        />
      </RowList>
      <Stack
        direction="column"
        css={{ width: '100%', marginTop: 'auto', marginBottom: '$16' }}
      >
        <Button
          isBlock
          as="button"
          onClick={() => {
            createAmplitudeEvent('registro_ppjj_comenzar_operar', {
              device,
              source: 'no_bis',
            });
            device === 'mobile'
              ? window.location.assign(ONE_LINK_URL)
              : window.open('/documents/crear-cuenta-Uala-desktop.pdf');
          }}
        >
          Comenzar a operar
        </Button>
        <Button
          isBlock
          as="button"
          variant="text"
          onClick={() => {
            createAmplitudeEvent('registro_ppjj_info', {
              device,
              source: 'no_bis',
            });
            history.push('/about-ppjj');
          }}
        >
          ¿Como sé si soy persona jurídica?
        </Button>
      </Stack>
    </LandingContainer>
  );
};

export default ApprovedPpffNoBis;
