import React, { useState } from 'react';
import { IconButton, Input } from '@uala/abra';
import { RegisterOptions, useFormContext } from 'react-hook-form';

type PasswordProps = {
  name: string;
  placeholder: string;
  isInvalid?: boolean;
  errorText?: string;
  registerOptions: RegisterOptions;
};

const Password = ({
  name,
  placeholder,
  isInvalid = false,
  errorText,
  registerOptions,
}: PasswordProps) => {
  const { register } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      action={
        <IconButton
          icon={showPassword ? 'hide-password' : 'show-password'}
          label="Ver contraseña"
          onClick={() => setShowPassword(!showPassword)}
        />
      }
      {...register(name, registerOptions)}
      isInvalid={isInvalid}
      errorText={errorText}
    />
  );
};

export default Password;
