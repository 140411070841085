import React from "react";
import { LeftArrowContainer } from "./styles";
import leftArrow from "../../assets/images/icon-arrow-left.png";

interface ArrowButtonProps {
  onClickFunction: () => void;
}

const LeftArrowButton: React.FC<ArrowButtonProps> = ({ onClickFunction }) => {
  return (
    <LeftArrowContainer>
      <img src={leftArrow} alt="back" onClick={onClickFunction} />
    </LeftArrowContainer>
  );
};

export default LeftArrowButton;
