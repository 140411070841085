import { useFormContext } from 'react-hook-form';
import { Button, Modal } from '@uala/ui';
import React, { useState, useEffect, useContext } from 'react';
import { InputElement } from '../../features/AccountRegistration/types';
import { Checkbox } from '@uala/ui';
import { StyledSelect, StyledOutlinedSelect } from '../Select/styles';
import styled from 'styled-components';
import { UserDataContext } from '../../features/contexts/userContext';
import PreviewInput from './PreviewInput';

const selectVariants = {
  standard: StyledSelect,
  outlined: StyledOutlinedSelect,
};

interface IProvince {
  name: string;
  value: boolean;
}

const MultiSelect: React.FC<InputElement> = ({
  name,
  placeholder,
  options = [],
}) => {
  const { setUser: authDispatch } = useContext(UserDataContext);
  const [rendered, setRendered] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    watch,
    setError,
    clearErrors,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { unregister } = useFormContext();
  const watchTaxType = watch('taxType') ? watch('taxType') : 'Local';
  const [selectedOptions, setSelectedOptions] = useState<IProvince[]>(
    getValues(name) ?? []
  );

  const SelectComponent = selectVariants['standard'];

  const handleCheckboxChange = (event: any) => {
    const selected = selectedOptions.find(
      (option) => option.name === event.target.name
    );
    if (selected) {
      const filteredDeletedOptions = selectedOptions.filter(
        (o) => o.name !== selected.name
      );
      setSelectedOptions(filteredDeletedOptions);
      setValue(name, filteredDeletedOptions);
    } else {
      const updateProvincies = [
        ...selectedOptions,
        {
          name: event.target.name,
          value: event.target.checked,
        },
      ];
      setSelectedOptions(updateProvincies);
      setValue(name, updateProvincies);
    }
  };

  const handleModalClose = async () => {
    authDispatch({ selectedProvincies: getCheckedOptions() }, true);
    clearErrors('taxJurisdictionMultiple');
    if (selectedOptions.length < 2) {
      setError('taxJurisdictionMultiple', {
        type: 'manual',
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    setRendered(watchTaxType === 'Multilateral');
    if (watchTaxType === 'Multilateral') {
      register(name, {
        required: true,
        validate: (v) => {
          return (
            v.length > 1 ||
            'Debe seleccionar mas de una provincia, de lo contrario seleccione tipo de régimen local'
          );
        },
      });
      return;
    }

    unregister(name);
  }, [watchTaxType]);

  const isOptionChecked = (label: any) => {
    const result = selectedOptions.find((option) => option.name === label);
    return result ? result.value : false;
  };

  const getCheckedOptions = () => {
    return selectedOptions.map((o) => o.name);
  };

  const onMouseOrKeyDown = (e: any) => {
    e.preventDefault();
    (document.activeElement as HTMLElement).blur();
    window.focus();
    setOpen(true);
  };

  return (
    <>
      {rendered && (
        <SelectComponent
          onMouseDown={onMouseOrKeyDown}
          onKeyDown={onMouseOrKeyDown}
        >
          <PreviewInput
            error={Boolean(errors[name])}
            placeholder={placeholder}
            value={
              !getCheckedOptions().length
                ? null
                : getCheckedOptions().join(', ')
            }
          />
          <Modal
            open={open}
            onClose={handleModalClose}
            title="Seleccióna las juridicciones del convenio multilateral"
            width="100%"
            px={[8, 20]}
            py={[8, 16]}
            pb={10}
          >
            <OptionsContainer>
              {options.map((option) => {
                return (
                  <ModalCheckboxContainer key={option.label}>
                    <Checkbox
                      aria-label={option.label}
                      name={option.label}
                      checked={isOptionChecked(option.label)}
                      onChange={handleCheckboxChange}
                    />
                    <CheckboxLabel>{option.label}</CheckboxLabel>
                  </ModalCheckboxContainer>
                );
              })}
            </OptionsContainer>
            <ModalButton
              mt={8}
              mx="auto"
              width="100%"
              maxWidth="312px"
              onClick={handleModalClose}
            >
              Aceptar
            </ModalButton>
          </Modal>
        </SelectComponent>
      )}
    </>
  );
};

export default MultiSelect;

const ModalButton = styled(Button)`
  display: flex;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ModalCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 5px 5px;
  width: 30%;

  @media (max-width: 425px) {
    width: 50%;
    margin: 0px 0px 5px 0px;
  }
  @media (max-width: 320px) {
    width: 100%;
    margin: 0px 0px 5px 0px;
  }
`;

const CheckboxLabel = styled.p`
  margin-left: 5px;
`;
