import Device from "../shared/types/device";
import useWindowSize from "./useWindowSize";

interface Props {
  breakpoint?: number;
}

const useDesktopSize = ({ breakpoint = 740 }: Props = {}): boolean => {
  const { width } = useWindowSize();

  return width > breakpoint;
};

export const useGetDevice = (): Device => useDesktopSize({ breakpoint: 1200 }) ? 'desktop' : 'mobile';

export default useDesktopSize;
