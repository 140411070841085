import React from 'react';

const AppStoreIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="91"
      height="20"
      viewBox="0 0 91 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_213_358)">
        <path
          d="M13.5356 10.6422C13.5356 8.07338 15.6391 6.88072 15.7305 6.78898C14.5416 5.04586 12.7124 4.86237 12.0722 4.77063C10.5175 4.58714 9.05415 5.68806 8.23104 5.68806C7.40792 5.68806 6.21898 4.77063 4.93858 4.77063C3.29235 4.86237 1.73757 5.87155 0.822999 7.33944C-0.91469 10.367 0.365712 14.9541 2.1034 17.4312C2.92652 18.6238 3.93255 20 5.21295 20C6.49335 19.9082 6.95064 19.1743 8.50541 19.1743C10.0602 19.1743 10.426 20 11.7979 20C13.1697 20 13.9928 18.8073 14.816 17.5229C15.822 16.1468 16.1878 14.7706 16.1878 14.6789C16.1878 14.6789 13.5356 13.6697 13.5356 10.6422Z"
          fill="#3A3A3A"
        />
        <path
          d="M11.0663 3.21101C11.7065 2.38532 12.2553 1.19266 12.0723 0C11.0663 0 9.87736 0.642202 9.1457 1.55963C8.5055 2.29358 7.95676 3.48624 8.04822 4.58716C9.1457 4.6789 10.3346 4.0367 11.0663 3.21101Z"
          fill="#3A3A3A"
        />
        <path
          d="M29.5407 13.1192H25.2422L24.2362 16.2385H22.407L26.5226 4.86237H28.3517L32.4673 16.2385H30.6382L29.5407 13.1192ZM25.6995 11.7431H29.1749L27.4372 6.78897H27.3457L25.6995 11.7431Z"
          fill="#3A3A3A"
        />
        <path
          d="M41.3387 12.1101C41.3387 14.6789 39.9668 16.3303 37.8633 16.3303C36.6744 16.3303 35.7598 15.7798 35.3025 14.8624V18.9908H33.5648V7.98164H35.211V9.35778C35.6683 8.44035 36.6744 7.88989 37.8633 7.88989C39.9668 7.88989 41.3387 9.54127 41.3387 12.1101ZM39.601 12.1101C39.601 10.4587 38.7779 9.35778 37.406 9.35778C36.1256 9.35778 35.211 10.4587 35.211 12.1101C35.211 13.7615 36.1256 14.8624 37.406 14.8624C38.6864 14.8624 39.601 13.7615 39.601 12.1101Z"
          fill="#3A3A3A"
        />
        <path
          d="M50.4844 12.1101C50.4844 14.6789 49.1125 16.3303 47.009 16.3303C45.8201 16.3303 44.9055 15.7798 44.4482 14.8624V18.9908H42.7105V7.98164H44.3568V9.35778C44.8141 8.44035 45.8201 7.88989 47.009 7.88989C49.0211 7.88989 50.4844 9.54127 50.4844 12.1101ZM48.6553 12.1101C48.6553 10.4587 47.8321 9.35778 46.4603 9.35778C45.1799 9.35778 44.2653 10.4587 44.2653 12.1101C44.2653 13.7615 45.1799 14.8624 46.4603 14.8624C47.8321 14.8624 48.6553 13.7615 48.6553 12.1101Z"
          fill="#3A3A3A"
        />
        <path
          d="M56.4291 13.0275C56.5206 14.1284 57.6181 14.8624 59.1728 14.8624C60.6362 14.8624 61.6422 14.1284 61.6422 13.1193C61.6422 12.2018 61.002 11.7431 59.5387 11.3761L58.0754 11.0092C55.9718 10.4587 55.0573 9.54128 55.0573 7.98165C55.0573 6.05504 56.795 4.67889 59.1728 4.67889C61.5507 4.67889 63.197 6.05504 63.2884 7.98165H61.5507C61.4593 6.88073 60.5447 6.14678 59.1728 6.14678C57.801 6.14678 56.8864 6.88073 56.8864 7.8899C56.8864 8.71559 57.5266 9.17431 58.9899 9.54128L60.2703 9.81651C62.5568 10.367 63.5628 11.2844 63.5628 12.9358C63.5628 15.0459 61.8251 16.422 59.1728 16.422C56.612 16.422 54.9658 15.1376 54.8744 13.0275H56.4291Z"
          fill="#3A3A3A"
        />
        <path
          d="M67.1296 5.96332V7.88992H68.6844V9.26607H67.1296V13.8532C67.1296 14.5872 67.404 14.8624 68.1356 14.8624C68.3186 14.8624 68.5929 14.8624 68.6844 14.8624V16.2386C68.5015 16.3303 68.1356 16.3303 67.7698 16.3303C66.1236 16.3303 65.4834 15.6881 65.4834 14.1285V9.35781H64.2944V7.88992H65.4834V5.96332H67.1296Z"
          fill="#3A3A3A"
        />
        <path
          d="M69.599 12.1101C69.599 9.54127 71.1537 7.88989 73.5316 7.88989C75.9095 7.88989 77.4643 9.54127 77.4643 12.1101C77.4643 14.7706 75.9095 16.3303 73.5316 16.3303C71.1537 16.3303 69.599 14.6789 69.599 12.1101ZM75.7266 12.1101C75.7266 10.2752 74.9035 9.26604 73.5316 9.26604C72.1598 9.26604 71.3367 10.2752 71.3367 12.1101C71.3367 13.9449 72.1598 14.9541 73.5316 14.9541C74.9035 14.9541 75.7266 13.8532 75.7266 12.1101Z"
          fill="#3A3A3A"
        />
        <path
          d="M78.8362 7.8899H80.4824V9.26605C80.7568 8.34862 81.4884 7.79816 82.4944 7.79816C82.7688 7.79816 82.9517 7.79816 83.0432 7.8899V9.44953C82.9517 9.35779 82.5859 9.35779 82.3115 9.35779C81.214 9.35779 80.5738 10.0917 80.5738 11.2844V16.2385H78.8362V7.8899Z"
          fill="#3A3A3A"
        />
        <path
          d="M90.9085 13.7615C90.7256 15.2294 89.1708 16.3303 87.3417 16.3303C84.9638 16.3303 83.409 14.6789 83.409 12.1101C83.409 9.54128 84.8723 7.79816 87.2502 7.79816C89.5367 7.79816 91 9.35779 91 11.9266V12.4771H85.1467V12.5688C85.1467 13.9449 86.0613 14.9541 87.3417 14.9541C88.2563 14.9541 88.9879 14.4954 89.2623 13.7615H90.9085ZM85.1467 11.2844H89.2623C89.2623 9.99999 88.4392 9.1743 87.2502 9.1743C86.1527 9.1743 85.2382 10.0917 85.1467 11.2844Z"
          fill="#3A3A3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_213_358">
          <rect width="91" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(AppStoreIcon);
