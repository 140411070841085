import React, { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useGetSecondStepFields from '../../hooks/useGetSecondStepFields';
import { UserDataContext } from '../contexts/userContext';
import { SkeletonContainer } from '../AccountRegistration/styles';
import CustomForm from '../BaseForm';
import { getErrorMessageByType } from './utils';
import { COUNTRY } from '../../shared/constants';
import { useSnackbar } from '@uala/ui';
import { postCompanyInfo } from '../../services/second-step';
import { checkResponseStatus } from '../../services/utils';
import FormContainer from '../../components/Layout/FormContainer';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { useGetDevice } from '../../hooks/useDesktopSize';
import RejectedState from '../../shared/types/rejectedState';
import useGtmEvent, { useGetGtmSubSectionName } from '../../hooks/useGtmEvent';

const CompanyInfo: React.FC = () => {
  const { data = { screens: [] } } = useGetSecondStepFields();
  const { user } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory<RejectedState>();
  const device = useGetDevice();
  const sendGtmEvent = useGtmEvent();
  const subSection = useGetGtmSubSectionName();

  const addSnackbar = useSnackbar();
  let { screenId } = useParams<{ screenId: string }>();

  const handleSubmit = async (data: any) => {
    const nextId = getScreenById(screenId)?.next_screen_id;
    if (nextId && hasNextScreen(nextId)) {
      history.push(`/second-step/${nextId}`);
    } else {
      try {
        setLoading(true);
        const response: any = await postCompanyInfo(COUNTRY, {
          ...data,
          ...user,
        });
        checkResponseStatus(response);
        sendGtmEvent('u.operation', {
          flow: 'registro empresas',
          step: subSection,
          step_number: 4,
        });
        if (user?.pep || data.obligatedSubject) {
          history.push('/rejected-compliance', {
            pep: !!user?.pep,
            obligatedSubject: data.obligatedSubject,
          });
          return;
        }
        history.push('/completed');
      } catch (error: any) {
        createAmplitudeEvent('registro_ppjj_error', {
          device,
          metaDataError: {
            message: error.message,
            statusCode: error.response?.status,
            resp: error.response?.data,
          },
          source: window.location.pathname,
        });
        addSnackbar(getErrorMessageByType(error.response?.data));
        setLoading(false);
      }
    }
  };

  const getScreenById = (id: string) =>
    data.screens.find((screen) => id === screen.id);

  const hasNextScreen = (screenId: string) => {
    return !!getScreenById(screenId);
  };

  const currentScreen = getScreenById(screenId);

  let { url } = useRouteMatch();

  const handleRouting = () => {
    const currentScreenId = url.split('/').pop();
    const nextScreenId = +currentScreenId! - 1;

    history.push(`/second-step/${nextScreenId}`);
  };

  const showBackButton = () => parseInt(currentScreen?.id!) > 1;

  const shouldSubmit = currentScreen?.next_screen_id === '';

  return (
    <FormContainer
      showBackButton={showBackButton()}
      handleRouting={handleRouting}
    >
      {currentScreen ? (
        <CustomForm
          screen={currentScreen}
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          loading={loading}
          shouldSubmit={shouldSubmit}
        />
      ) : (
        <SkeletonContainer>
          <div style={{ lineHeight: 2, marginBottom: '15px' }}>
            <Skeleton height={40} count={2} />
          </div>
          <div style={{ lineHeight: 2 }}>
            <Skeleton height={50} count={5} />
          </div>
        </SkeletonContainer>
      )}
    </FormContainer>
  );
};

export default CompanyInfo;
