import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    font-family: 'Public Sans', sans-serif;
  }
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: sans-serif;
    background-color: #ffffff;
    @media (max-width: 480px) {
      background-color: transparent;
   }
  }
`;

export default GlobalStyles;
