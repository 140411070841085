import { endpoints } from '../../shared/constants';
import { verificationCodeInstance } from '../../shared/axios';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';

const validateUserSignupCode = async (code: string, email: string) => {
  const body = {
    pin: code,
    email,
  };
  const response = await verificationCodeInstance.post(
    endpoints.VERIFY_CODE,
    body
  );
  return response.data;
};

const resendVerificationCodeByEmail = async (email: string) => {
  const body = {
    email,
  };
  return await verificationCodeInstance.post(endpoints.RESEND_CODE, body);
};

const signInUserToCognito = async (email: string, password: string) => {
  let user;
  try {
    user = await Auth.signIn(email, password);
  } catch (error) {
    console.log(error);
  }

  const session = await Auth.userSession(user);
  const token = session.getIdToken().getJwtToken();

  Cookies.set('sessionToken', token, { expires: session.getIdToken().getExpiration(), secure: true, sameSite: 'Strict' })

  return user;
};

export {
  validateUserSignupCode,
  resendVerificationCodeByEmail,
  signInUserToCognito,
};
