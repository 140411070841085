import styled from 'styled-components';

export const CommissionsSection = styled.section`
  width: 100%;
  background-color: #eef3ff;
  padding-bottom: 74px;
  display: flex;

  @media (max-width: 1200px) {
    display: initial;
    padding-bottom: 35px;
  }
`;

export const CommissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px auto 40px auto;
  @media (max-width: 1200px) {
    margin: 0 auto;
    padding-top: 28px;
    padding-bottom: 70px;
    background-color: #eef3ff;
  }
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #3a3a3a;

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const CommissionsCardsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const CommissionsDisclosures = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #636363;
  min-width: 200px;
  max-width: 339px;
  align-self: center;
`;
