import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { getEventNameByPathname } from '../../utils/amplitude';
import { ButtonWatcher } from '../AccountRegistration/ButtonWatcher';
import { FormContainer, Title, Subtitle } from '../AccountRegistration/styles';
import { IFormScreen, InputElement } from '../AccountRegistration/types';
import { renderInputByType } from '../AccountRegistration/utils';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import useGtmEvent, { useGetGtmSubSectionName } from '../../hooks/useGtmEvent';

interface CustomFormProps {
  screen: IFormScreen;
  onSubmit: (data: any, methods: any) => void;
  loading: boolean;
  shouldSubmit: boolean;
}

const CustomForm: React.FC<CustomFormProps> = ({
  screen,
  onSubmit,
  loading,
  shouldSubmit,
}) => {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const sendGtmEvent = useGtmEvent();
  const subSectionName = useGetGtmSubSectionName();
  const { handleSubmit, watch, unregister } = methods;
  const device = useGetDevice();
  const grossEarnings = watch('grossEarnings');

  useEffect(() => {
    if (!grossEarnings) {
      unregister('taxJurisdictionMultiple');
    }
  }, [grossEarnings, unregister]);

  const handleFormSubmit = async (data: any) => {
    await methods.trigger();
    let errors = methods.formState.errors;
    sendGtmEvent('u.user_interaction', {
      section: 'empresas',
      subsection: subSectionName,
      action: 'seleccionar',
      element: screen.submit_button,
    });

    if (Object.keys(errors).length === 0) {
      createAmplitudeEvent(getEventNameByPathname(), { device }, data);
      await onSubmit(data, methods);
    }
  };

  const handleCustomValidations = (input: any) => {
    if (input.name === 'cuit') {
      input.validations = {
        ...input.validations,
        validate: (value: number) =>
          value.toString().startsWith('30') ||
          value.toString().startsWith('33') ||
          'El CUIT no corresponde a una empresa',
      };
    }
    if (input.name === 'taxNumber') {
      input.validations = {
        validate: (value: number) => {
          if (watch('taxType') !== 'Exento' && !value) {
            return 'El campo es requerido';
          }
          return true;
        },
      };
    }
  };

  return (
    <>
      <Title>{screen.title}</Title>
      {screen.subtitle && <Subtitle>{screen.subtitle}</Subtitle>}
      <FormProvider {...methods}>
        <FormContainer>
          {screen.inputs.map((input: InputElement, index: number) => {
            handleCustomValidations(input);
            return renderInputByType(input, index, methods);
          })}
        </FormContainer>
        {screen.submit_button && (
          <ButtonWatcher
            onClick={
              shouldSubmit ? handleSubmit(handleFormSubmit) : handleFormSubmit
            }
            loading={loading}
          >
            {screen.submit_button}
          </ButtonWatcher>
        )}
      </FormProvider>
    </>
  );
};

export default CustomForm;
