import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_FIRST_STEP_API_URL,
});

export const verificationCodeInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
});

export const secondStepInstance = axios.create({
  baseURL: process.env.REACT_APP_SECOND_STEP_API_URL,
});

export const userIntance = axios.create({
  baseURL: process.env.REACT_APP_USER_API_URL,
});
