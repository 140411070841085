import styled from 'styled-components';

const BenefitsList = styled.ul`
  color: var(--gris-uala);
  margin-right: auto;
  max-width: 80%;
  list-style: none;

  li {
    font-size: 18px;
    line-height: 24px;
    background: url('/images/bullet.svg') no-repeat 0 0;
    padding-left: 25px;
    background-position: left;
    margin-bottom: 5px;
  }

  @media (max-width: 1200px) {
    margin-left: auto;
    list-style: disc;
    li {
      padding-left: 0;
      background: none;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0px;
    }

    li::marker {
      color: #9cb3fd;
      font-size: 2em;
      line-height: 0.6;
    }
  }
`;

export default BenefitsList;
