import React from 'react';
import {
  CommissionsCardsContainer,
  CommissionsContainer,
  CommissionsDisclosures,
  CommissionsSection,
  Title,
} from './styles';
import CommisionCard from '../../components/CommisionCard';

const Commissions: React.FC = () => (
  <CommissionsSection>
    <CommissionsContainer>
      <Title>Comisiones bajas en serio</Title>
      <CommissionsCardsContainer>
        <CommisionCard
          title="Link de Pago/Tiendaonline"
          items={[
            { title: 'Crédito y prepaga', text: '4,9%', subText: '+IVA' },
            { title: 'Débito', text: '4,9%', subText: '+IVA' },
          ]}
        />
        <CommisionCard
          title="mPOS"
          items={[
            { title: 'Crédito y prepaga', text: '4,9%', subText: '+IVA' },
            { title: 'Débito', text: '2,9%', subText: '+IVA' },
          ]}
        />
        <CommisionCard
          title="QR"
          items={[{ title: 'Saldo en cuenta', text: '0%*', subText: '' }]}
          small
        />
        <CommissionsDisclosures>
          *Los primeros <b>3 meses</b> vas a tener 0% de costo de comisión, en
          concepto del Tramo Gratuito dispuesto en la regulación del BCRA. Una
          vez finalizados, por cada pago recibido vas a pagar{' '}
          <b>0,8% de comisión</b> en todos los medios de pago.
        </CommissionsDisclosures>
      </CommissionsCardsContainer>
    </CommissionsContainer>
  </CommissionsSection>
);

export default Commissions;
