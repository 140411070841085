import React, { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputElement } from "../../features/AccountRegistration/types";
import { Input } from "@uala/ui";

const PasswordInput: React.FC<InputElement> = (
  { name, placeholder, type, description, options = [], validations },
  props: any
) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordButton = () => (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      }}
    >
      {showPassword ? "Ocultar" : "Mostrar"}
    </button>
  );

  const password = useRef({});
  if (name === "repeatPassword") {
    password.current = watch("password", "");
    validations = {
      ...validations,
      validate: (value: string) =>
        value === password.current || "Las contraseñas no coinciden",
    };
  }

  return (
    <Input
      {...register(name, validations)}
      id={name}
      label={placeholder}
      {...props}
      width="100%"
      error={errors[name] ? true : false}
      helperText={description ? description : errors[name]?.message}
      rightAddon={showPasswordButton()}
      type={showPassword ? "text" : "password"}
    />
  );
};

export default PasswordInput;
