import { Checkbox } from '@uala/ui';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { InputElement } from '../../features/AccountRegistration/types';
import { createAmplitudeEvent } from '../../features/contexts/AmplitudeProvider';
import { useGetDevice } from '../../hooks/useDesktopSize';

const CheckboxInput: React.FC<InputElement> = (
  { name, placeholder, validations, defaultValue = false },
  props: any
) => {
  let ref = useRef(null);
  const { register } = useFormContext();
  const [checked, setChecked] = useState(Boolean(defaultValue));
  const handleCheckboxChange = () => setChecked(!checked);
  const device = useGetDevice();

  useEffect(() => {
    const { current } = ref;
    if (current) {
      const link = (current as HTMLInputElement).getElementsByTagName('a')[0];
      if (link.text === 'Términos y Condiciones') {
        link.onclick = () => {
          createAmplitudeEvent('registro_ppjj_visualiza_tyc', { device });
        };
      }
    }
  }, [ref, device]);

  return (
    <CheckboxContainer ref={ref}>
      <Checkbox
        aria-label={placeholder}
        {...register(name, validations)}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <ReactMarkdown children={placeholder} linkTarget="_blank" />
    </CheckboxContainer>
  );
};

export default CheckboxInput;

const CheckboxContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 50px 70%;
  flex-direction: row;
  align-items: center;
  margin: 40px 0 0 0;
`;
