import useWindowSize from './useWindowSize';

declare global {
  interface Window { dataLayer: any[]; }
}

const useGtmEvent = () => {
  const { width } = useWindowSize();
  return (event: string, parameters: object) => {
    if (width > 0 && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event,
        parameters: { ...parameters, section: 'empresas' }
      });
    }
  }
}

export const useGetGtmSubSectionName = () => {
  const { pathname } = window.location
  const pageSection = pathname.slice(1, pathname.indexOf('/', 1))
  switch (pageSection) {
    case 'first-step':
      return 'datos de la persona apoderada'
    case 'verify':
      return 'verificar'
    case 'second-step':
      return 'datos de la empresa'
    default:
      return ''
  }
}

export default useGtmEvent
