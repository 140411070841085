import React, { useContext } from 'react';
import LandingContainer from '../../components/Layout/LandingContainer';
import { Link, Illustration, Title, Text, Button } from '@uala/abra';
import { UserDataContext } from '../contexts/userContext';
import { useHistory } from 'react-router-dom';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';

const Rejected = () => {
  const history = useHistory();
  const device = useGetDevice();
  const { user } = useContext(UserDataContext);
  useAmplitudEvent('registro_ppjj_registro_finalizado', {
    reason: 'no_aprobado',
  });

  return (
    <LandingContainer
      showNavBar
      showBackButton
      backButtomFunction={() => history.push('/first-step/1')}
    >
      <Illustration name="large-error-informacion" />
      <Title align="center">Necesitamos que nos contactes</Title>
      <Text size="lg" align="center" css={{ maxWidth: 320 }}>
        Por favor, escribinos a <Link>hola@ualabis.com.ar</Link> para finalizar
        tu registro.
      </Text>
      <Button
        isBlock
        as="a"
        onClick={() =>
          createAmplitudeEvent('registro_ppjj_contacta', {
            device,
            source: 'no_aprobado',
            contacto: 'mail',
          })
        }
        href={`mailto:hola@ualabis.com.ar?subject=PPJJ%20-%20Quiero%20finalizar%20mi%20registro&body=¡Hola!%0D%0AMe%20contacto%20por%20el%20registro%20pendiente%20relacionado%20con%20el%20email%20${
          user?.accountEmail || '[Tu email]'
        }%0D%0AMi%20CUIT%20es%20%5Bcompletar%20CUIT%5D.%E2%80%A8Muchas%20gracias.`}
        css={{ marginTop: 'auto', marginBottom: '$32', textDecoration: 'none' }}
      >
        Enviar email
      </Button>
    </LandingContainer>
  );
};

export default Rejected;
