import { Label, styled, typography } from '@uala/abra';
import { CSS } from '@uala/abra/dist/src/theme/config';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type SelectProps = {
  options: Array<{
    value?: string;
    name: string;
  }>;
  label: string;
  isInvalid?: boolean;
  register: UseFormRegisterReturn;
  css?: CSS;
};

const Select = ({
  options,
  label,
  isInvalid = false,
  register,
  css,
}: SelectProps) => {
  return (
    <SelectDiv css={css}>
      <SelectSubDiv
        css={{
          borderColor: isInvalid ? 'var(--colors-error-middle)' : undefined,
        }}
      >
        <Label size="sm" variant="default">
          {label}
        </Label>
        <StyledSelect defaultValue="" {...register}>
          {options.map((option) => (
            <option
              key={`option-${option.value ?? option.name}`}
              value={option.value ?? option.name}
            >
              {option.name}
            </option>
          ))}
        </StyledSelect>
      </SelectSubDiv>
    </SelectDiv>
  );
};

const StyledSelect = styled('select', {
  backgroundColor: 'transparent',
  border: 'none',
  color: '$neutral-darker',
  margin: 0,
  minHeight: 47,
  padding: '0 $8',
  unset: 'all',
  width: '100%',
  zIndex: 2,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '&::-webkit-outer-spin-button,  &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$neutral-dark',
  },
  '&:disabled': {
    color: '$neutral-middle',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: '$neutral-middle',
    },
  },
  variants: {
    /**
     * Modifica el tamaño del input
     * @default 'sm'
     */
    size: {
      sm: {
        ...typography.label.md.regular,
      },
      md: {
        ...typography.number.md.light,
      },
      lg: {
        ...typography.number.lg.light,
        paddingTop: 2,
      },
    },
  },
});

const SelectDiv = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'var(--transitions-default)',
});

const SelectSubDiv = styled('div', {
  color: 'var(--colors-neutral-darker)',
  borderBottom: 'var(--borderWidths-thin) solid var(--colors-neutral-middle)',
  display: 'flex',
  flexDirection: 'column',
  transition: 'var(--transitions-default)',
  position: 'relative',
});

export default Select;
