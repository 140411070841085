import React from 'react'
import LandingContainer from '../../components/Layout/LandingContainer'
import { Illustration, Title, Text, Button, Stack } from '@uala/abra'
import { useHistory } from 'react-router-dom'
import { useGetDevice } from '../../hooks/useDesktopSize'
import useAmplitudEvent from '../../hooks/useAmplitudEvent'
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider'
import { redirectToApp } from '../../utils/redirectoToApp'

const ApprovedPpff = () => {
  const device = useGetDevice();
  const history = useHistory();
  useAmplitudEvent('registro_ppjj_ya_registrado_uala', { reason: 'bis' });

  return (
    <LandingContainer showNavBar showBackButton backButtomFunction={() => history.push('/first-step/1')}>
      <Illustration name='large-cobro-pospro' />
      <Title align='center'>Ya podés cobrar desde la app de Ualá</Title>
      <Text size='lg' align='center' css={{ maxWidth: 320 }}>
        Si sos personan jurídica y querés aumentar tus límites de venta, usá otro email para registrarte.
      </Text>
      <Stack direction='column' css={{ width: '100%', marginTop: 'auto', marginBottom: '$16' }}>
        <Button
          isBlock
          as='button'
          onClick={() => {
            createAmplitudeEvent('registro_ppjj_comenzar_operar', { device, source: 'bis' })
            redirectToApp(device)
          }}
        >
          Cobrar por Ualá Bis
        </Button>
        <Button
          isBlock
          as='button'
          variant='text'
          onClick={() => {
            createAmplitudeEvent('registro_ppjj_info', { device, source: 'bis' })
            history.push('/about-ppjj');
          }}
        >
          ¿Como sé si soy persona jurídica?
        </Button>
      </Stack>
    </LandingContainer>
  )
}

export default ApprovedPpff