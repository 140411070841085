import React from 'react';
import { createAmplitudeEvent } from '../../../features/contexts/AmplitudeProvider';
import { useGetDevice } from '../../../hooks/useDesktopSize';
import { IconButton } from '@uala/abra';

const FaqIcon = () => {
  const device = useGetDevice();
  return (
    <IconButton
      icon='info-circle'
      variant='neutral'
      label='faqs'
      as='a'
      href='https://www.ualabis.com.ar/faqs'
      target='_blank'
      css={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: 0,
        right: 10,
        '@lg': {
          right: -25,
          top: -15
        }
      }}
      onClick={() => {
        createAmplitudeEvent('registro_ppjj_selecciona_faqs', {
          device,
          source: window.location.pathname,
        });
      }}
    />
  )
};

export default FaqIcon;
