import React from 'react';
import LandingContainer from '../LandingContainer';
import { LandingContainerProps } from '../LandingContainer/LandingContainer';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { Alert, Button, Stack, Title, styled } from '@uala/abra';

type FormContainerProps = LandingContainerProps & {
  formMethods: UseFormReturn<FieldValues, any, undefined>;
  onSubmit: (data: any) => Promise<void>;
  isLoading: boolean;
  errorText?: string;
  title: string;
  subTitle?: string | null;
};

const FormContainer = ({
  showNavBar,
  showBackButton,
  showFaq,
  backButtomFunction,
  formMethods,
  onSubmit,
  isLoading,
  children,
  errorText,
  title,
  subTitle,
}: FormContainerProps) => {
  return (
    <LandingContainer
      showNavBar={showNavBar}
      showBackButton={showBackButton}
      showFaq={showFaq}
      backButtomFunction={backButtomFunction}
    >
      <Stack direction="column" css={{ width: '100%', height: '100%' }}>
        {errorText && <Alert variant="error" description={errorText} />}
        <Title align="left" css={{ width: '100%' }}>
          {title}
        </Title>
        {subTitle && (
          <Title size="sm" color="light">
            {subTitle}
          </Title>
        )}

        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack direction="column" css={{ width: '100%', height: '100%' }}>
              {children}
              <Button
                css={{ marginTop: 'auto', marginBottom: '$16' }}
                isBlock
                type="submit"
                isDisabled={isLoading}
              >
                Continuar
              </Button>
            </Stack>
          </Form>
        </FormProvider>
      </Stack>
    </LandingContainer>
  );
};

const Form = styled('form', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export default FormContainer;
