import React from 'react';
import List from '../../components/UI/List';
import { Button, Text, Title } from '@uala/abra';
import LandingContainer from '../../components/Layout/LandingContainer';
import { useHistory } from 'react-router-dom';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { useGetDevice } from '../../hooks/useDesktopSize';

const AboutPpjj = () => {
  const history = useHistory();
  const device = useGetDevice();
  return (
    <LandingContainer showNavBar>
      <Title
        align="left"
        as="h1"
        css={{ marginTop: 22, width: '100%', '@md': { textAlign: 'center' } }}
      >
        Cómo sé si soy persona jurídica
      </Title>
      <Text>Verificá que tu empresa cumpla con estos tres requisitos:</Text>

      <List>
        <Text>
          El{' '}
          <Text as="strong" weight="bold">
            CUIT
          </Text>{' '}
          empieza con{' '}
          <Text as="strong" weight="bold">
            30
          </Text>{' '}
          o{' '}
          <Text as="strong" weight="bold">
            33.
          </Text>
        </Text>
        <Text>
          Está inscripta en el{' '}
          <Text as="strong" weight="bold">
            Registro Nacional de Sociedades.
          </Text>
        </Text>
        <Text>
          Tiene al menos una{' '}
          <Text as="strong" weight="bold">
            persona apoderada.
          </Text>
        </Text>
      </List>

      <Button
        isBlock
        css={{ marginTop: 'auto' }}
        onClick={() => {
          createAmplitudeEvent('registro_ppjj_info_selecciona_comenzar', {
            device,
          });
          history.push('/first-step/1');
        }}
      >
        Comenzar registro
      </Button>
      <Button isBlock onClick={() => history.goBack()} variant="text">
        Volver
      </Button>
    </LandingContainer>
  );
};

export default AboutPpjj;
