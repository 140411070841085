import { useQuery, UseQueryResult } from "react-query";
import { ISignupFieldsInterface } from "../features/AccountRegistration/types";
import { getSecondStepFields } from "../services/second-step";
import { COUNTRY, secondStepProduct } from "../shared/constants";

const useGetSecondStepFields = (): UseQueryResult<
  ISignupFieldsInterface,
  unknown
> => {
  return useQuery<ISignupFieldsInterface>(
    ["second-step", COUNTRY, secondStepProduct],
    () => getSecondStepFields(COUNTRY, secondStepProduct)
  );
};

export default useGetSecondStepFields;
