import { SubmitButton } from './styles';
import styled from 'styled-components';

export const ButtonWatcher: React.FC<{
  loading?: boolean;
  onClick?: Function;
}> = ({ loading = false, children, onClick = () => { } }) => {
  return (
    <ButtonsContainer>
      <SubmitButton
        onClick={() => {
          if (!loading) {
            onClick();
          }
        }}
        disabled={loading}
      >
        {children}
      </SubmitButton>
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  position: sticky;
  display: flex;
  bottom: 78px;
  margin-top: auto;
  width: 100%;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    max-width: 100%;
  }
`;
