import { Input } from '@uala/ui';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputElement } from '../../features/AccountRegistration/types';

const TextInput: React.FC<InputElement> = (
  { name, placeholder, type, description = '', validations },
  props: any
) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const [rendered, setRendered] = useState(true);
  const [isInFocus, setIsInFocus] = useState(false);
  const watchTaxType = watch('taxType') ? watch('taxType') : 'Local';
  const inputField = register(name, validations);

  useEffect(() => {
    if (name === 'taxNumber') {
      setRendered(watchTaxType !== 'Exento');
    }
  }, [name, watchTaxType]);

  const onWheel = (e: any) => {
    e.currentTarget.blur();
  };

  const blockInvalidInputs = (e: any) => {
    if (
      type === 'number' &&
      ['e', 'E', '+', '-', '.', 'ArrowUp', 'ArrowDown'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const handleInputMode = (fieldType: string) => {
    switch (fieldType) {
      case 'number':
        return 'numeric';
      case 'email':
        return 'email';
      default:
        return 'text';
    }
  };

  return (
    <>
      {rendered && (
        <Input
          {...inputField}
          onFocus={() => {
            setIsInFocus(true);
          }}
          onBlur={(e: any) => {
            inputField.onBlur(e).finally(() => {
              setIsInFocus(false);
            });
          }}
          id={name}
          label={placeholder}
          type={type}
          onWheel={onWheel}
          width="100%"
          {...props}
          error={Boolean(errors[name])}
          helperText={
            errors[name] ? errors[name]?.message : !isInFocus || description
          }
          onKeyDown={blockInvalidInputs}
          inputMode={handleInputMode(type)}
        />
      )}
    </>
  );
};

export default TextInput;
