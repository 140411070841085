import React from 'react';
import { Stack } from '@uala/abra';
import { useFormContext } from 'react-hook-form';
import InputCheck from './InputCheck';
import Password from '../../../components/UI/Inputs/Password';

const PasswordInput = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const passwordInput = watch('password', '') as string;

  return (
    <>
      <Password
        name="password"
        placeholder="Ingresá una contraseña"
        registerOptions={{
          required: 'El campo es requerido',
          minLength: 8,
          validate: {
            upperCase: (v: string) => /[A-Z]/.test(v),
            lowerCase: (v: string) => /[a-z]/.test(v),
            number: (v: string) => /\d/.test(v),
          },
        }}
        isInvalid={!!errors['password']}
      />
      <Stack direction="column" css={{ padding: '$8' }}>
        <InputCheck
          isValid={passwordInput.length >= 8}
          text="Mínimo 8 caracteres"
        />
        <InputCheck
          isValid={/[A-Z]/.test(passwordInput)}
          text="Mínimo 1 mayúscula"
        />
        <InputCheck
          isValid={/[a-z]/.test(passwordInput)}
          text="Mínimo 1 minúscula"
        />
        <InputCheck isValid={/\d/.test(passwordInput)} text="Mínimo 1 número" />
      </Stack>
    </>
  );
};

export default PasswordInput;
