import React, { FC } from 'react';
import {
  Container,
  RightContainer,
  SubContainer,
} from '../../../features/AccountRegistration/styles';
import useDesktopSize from '../../../hooks/useDesktopSize';
import LeftArrowButton from '../../BackwardButton';
import Navbar from '../../UI/Navbar';

const FormContainer: FC<{
  showBackButton?: boolean;
  handleRouting?: Function;
}> = ({ children, showBackButton = false, handleRouting = () => {} }) => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  return (
    <RightContainer>
      {!isDesktop && (
        <Navbar
          showBackButton={showBackButton}
          backButtomFunction={() => handleRouting()}
          showFaq
        />
      )}
      <Container>
        <SubContainer>
          {isDesktop && showBackButton && (
            <LeftArrowButton onClickFunction={() => handleRouting()} />
          )}
          {children}
        </SubContainer>
      </Container>
    </RightContainer>
  );
};

export default FormContainer;
