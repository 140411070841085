const initializeGTM = (gmtId: string) => {
  const l = 'dataLayer'
  window[l] = window[l] || [];
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const firstScriptElement = document.getElementsByTagName('script')[0];
  const gtmScript = document.createElement('script');
  const dl = l !== 'dataLayer' ? '&l=' + l : '';

  gtmScript.async = true;
  gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${gmtId + dl}`
  if (firstScriptElement.parentNode) {
    firstScriptElement.parentNode.insertBefore(gtmScript, firstScriptElement);
  }
}

export default initializeGTM