import React, { useContext } from 'react';
import { Illustration, Title, Button, Text, Link, Stack } from '@uala/abra';
import { getEventNameByPathname } from '../../utils/amplitude';
import useAmplitudEvent from '../../hooks/useAmplitudEvent';
import {
  AmplitudeContext,
  createAmplitudeEvent,
  setUserProp,
} from '../contexts/AmplitudeProvider';
import { useGetDevice } from '../../hooks/useDesktopSize';
import Cookies from 'js-cookie';
import { redirectToApp } from '../../utils/redirectoToApp';

const Completed = () => {
  sessionStorage.removeItem('user');
  Cookies.remove('sessionToken');
  const device = useGetDevice();

  const { isInitiated } = useContext(AmplitudeContext);
  if (isInitiated) {
    setUserProp('user_type', 'COMPANY');
  }
  useAmplitudEvent(getEventNameByPathname(), { success: true });

  const onContact = (type: string) =>
    createAmplitudeEvent('registro_ppjj_contacta', {
      device,
      contacto: type,
      source: 'fin_registro',
    });
  const onClickButton = () => {
    createAmplitudeEvent(
      device === 'mobile'
        ? 'registro_ppjj_descarga_app'
        : 'registro_ppjj_ingresa_webapp',
      { device },
      {},
      () => redirectToApp(device)
    );
  };

  return (
    <Stack
      direction="column"
      align="center"
      css={{ marginRight: 'auto', marginLeft: 'auto' }}
    >
      <Stack
        direction="column"
        css={{
          margin: '64px auto auto',
          padding: 20,
          height: '100%',
          maxWidth: 400,
        }}
        spacing={0}
      >
        <Illustration
          css={{ margin: '0 auto 40px' }}
          name="large-exito-fotografia-selfie-documento"
        />
        <Title size="lg" align="center">
          ¡Listo! Completaste tu registro{' '}
        </Title>

        <Text align="center" css={{ marginTop: '20px' }}>
          Te enviamos un email en el que te pedimos documentación para aumentar
          tu límite de ventas y habilitar transferencias a terceros
        </Text>

        <Stack
          direction="column"
          css={{ marginTop: 'auto', '@md': { marginBottom: 74 } }}
        >
          <Button isBlock onClick={onClickButton}>
            {device === 'mobile' ? 'Descargá la app' : 'Ingresar'}
          </Button>
          <Text size="sm" align="center">
            Podés contactarnos por{' '}
            <Link
              onClick={() => onContact('whatsapp')}
              css={{ color: '$primary-middle', fontWeight: '$bold !important' }}
              target="_blank"
              href="https://wa.me/5491145261066"
            >
              WhatsApp
            </Link>
          </Text>
          <Text size="sm" align="center">
            o enviarnos un email a{' '}
            <Link
              onClick={() => onContact('mail')}
              css={{ color: '$primary-middle', fontWeight: '$bold !important' }}
              target="_blank"
              href="mailto:hola@ualabis.com.ar"
            >
              hola@ualabis.com.ar
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Completed;
