import { useEffect, createContext, useMemo } from 'react';
import amplitude from 'amplitude-js';
import {
  getOptionsByPathname,
  getOptionsByUrlSearch,
} from '../../utils/amplitude';
import { useState } from 'react';

export const setUserProp = (key: string, value: string | number) => {
  const identify = new amplitude.Identify().set(key, value);
  amplitude.getInstance().identify(identify);
};

export const createAmplitudeEvent = (
  name: string,
  params: object = {},
  formData?: object,
  callback?: () => void
): void => {
  const options = {
    ...params,
    country: process.env.REACT_APP_ONBOARDING_COUNTRY,
    ...getOptionsByPathname(formData),

    ...getOptionsByUrlSearch(name),
  };
  amplitude.getInstance().logEvent(name, options, callback, callback);
};

export const AmplitudeContext = createContext({ isInitiated: false });

export const setAmplitudUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

const AmplitudeProvider: React.FC = ({ children }) => {
  const [isInitiated, setIsInitiated] = useState(false);

  useEffect(() => {
    if (!isInitiated) {
      amplitude
        .getInstance()
        .init(
          process.env.REACT_APP_PUBLIC_AMPLITUDE_API_KEY ?? '',
          undefined,
          undefined,
          () => {
            setIsInitiated(true);
          }
        );
    }
  }, [isInitiated]);

  const contextValue = useMemo(
    () => ({ isInitiated: isInitiated }),
    [isInitiated]
  );
  return (
    <AmplitudeContext.Provider value={contextValue}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export default AmplitudeProvider;
