export const COUNTRY = process.env.REACT_APP_ONBOARDING_COUNTRY!;
export const secondStepProduct = process.env.REACT_APP_SECOND_STEP_PRODUCT!;

export const endpoints = {
  FIRST_STEP: '/1/onboarding/first-step',
  VERIFY_CODE: '/1/auth/mail',
  RESEND_CODE: '/1/auth/resend-code',
  SECOND_STEP: '/1/onboarding/second-step',
};

export const ONE_LINK_URL = 'https://uala.onelink.me/tTSW/debd1ee8';
