import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../../components/Layout/FormContainer';
import { ButtonWatcher } from '../AccountRegistration/ButtonWatcher';
import {
  FormContainer as SubContainer,
  Subtitle,
  Title,
} from '../AccountRegistration/styles';
import { useGetDevice } from '../../hooks/useDesktopSize';
import { createAmplitudeEvent } from '../contexts/AmplitudeProvider';
import { getEventNameByPathname } from '../../utils/amplitude';

const PersonalInfo = () => {
  const history = useHistory();
  const device = useGetDevice();
  const handleSubmit = () => {
    createAmplitudeEvent(getEventNameByPathname(), { device });
    history.push('/first-step/1');
  };
  return (
    <Container>
      <SubContainer>
        <Image src="/images/personal-info.png" alt="Informacion Personal" />
        <CustomTitle>Te vamos a pedir algunos datos de la empresa</CustomTitle>
        <CustomSubTitle>
          Solo te va a tomar un par de minutos. Para registrarte vas a
          necesitar:
        </CustomSubTitle>

        <ListContainer>
          <div>
            <img src="/images/checked.svg" alt="" />
            <p>Crear un usuario y contraseña</p>
          </div>
          <div>
            <img src="/images/checked.svg" alt="" />
            <p>Número de DNI (del apoderado)</p>
          </div>
          <div>
            <img src="/images/checked.svg" alt="" /> <p>CUIT y Razón social</p>
          </div>
        </ListContainer>
        <ButtonWatcher onClick={handleSubmit}>Comenzar</ButtonWatcher>
      </SubContainer>
    </Container>
  );
};

const Image = styled.img`
  display: flex;
  margin: 2rem auto;
  max-width: 100%;
  width: 60%;
`;

const CustomTitle = styled(Title)`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #3a3a3a;
`;

const CustomSubTitle = styled(Subtitle)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3a3a3a;
`;

const ListContainer = styled.div`
  width: fit-content;
  display: flex;
  margin: auto;
  flex-direction: column;
  div {
    display: flex;
    p {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #565656;
    }
  }
`;

export default PersonalInfo;